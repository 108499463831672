<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0">
      <Textarea
        :id="id"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :disabled="readonly || false"
        class="block"
        rows="8" cols="500"
      >
      </Textarea>
      <mkeFormBuilderTranslationDialog v-if="translate == true" :fieldName="field" :translate="translate"
                                       :translationModel="translationModel" v-on:updateTranslation="$emit('update:modelValue', $event)"/>
    </div>
  </div>
</template>

<script>
/**
 * An input field to show / edit a text area. It is possible to show translated texts
 *
 * @vue-prop {string} field - The name of the field. Used for translation purpose.
 * @vue-prop {string} id - The id for the field
 * @vue-prop {string} label - The label for the field
 * @vue-prop {*} modelValue - The value to display
 * @vue-prop {string} placeholder - A text if nothing is in the field
 * @vue-prop {boolean} readonly - Determines if the field is readonly
 * @vue-prop {boolean} translate - Determines if the field can have translations
 * @vue-prop {string} translationModel - The model used to create/update a translation. For example: use 'product'
 *                                       if you want to create/update a translation for the model 'product'.
 *
 * @emits update:modelValue - Emitted when the component's modelValue or the corresponding translation is updated.
 */
export default {
  name: "mkeInput",
  props: {
    "field": String,
    "id": String,
    "label": String,
    "modelValue": null,
    "placeholder": String,
    "readonly": Boolean,
    "translate": Boolean,
    "translationModel": String
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped></style>
