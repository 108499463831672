<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0" :data-value="modelValue">
      <Dropdown :id="id" class="block" :modelValue="modelValue" :options="options" :optionLabel="optionLabel ? optionLabel : 'name'" :optionValue="optionValue ? optionValue : 'id'" :filter="true"
            :placeholder="placeholder" :showClear="true" @change="$emit('update:modelValue', $event.value)"/>
    </div>
  </div>
</template>

<script>
/**
 * A dropdown list to select a value from the given options.
 *
 * @vue-prop {string} id - The id for the dropdown element
 * @vue-prop {string} label - The label for the field
 * @vue-prop {*} modelValue - The selected value to display
 * @vue-prop {Array.<Object>} options - The available entries for the dropdown field.
 * @vue-prop {string} optionLabel - Determines which key of the options determines the label
 * @vue-prop {string} optionValue -Determines which key of the options determines the value of the field
 * @vue-prop {string} placeholder - A text if no entry is selected
 * @vue-prop {boolean} readonly - Determines if the field is readonly
 *
 * @emits update:modelValue - Emitted when the component's modelValue is updated.
 */
export default {
  name: "mkeFormBuilderDropdown",
  props: {
    "id": String,
    "label": String,
    "modelValue": null,
    "options": Array,
    "optionLabel": String,
    "optionValue": String,
    "placeholder": String,
    "readonly": Boolean
  },
}
</script>

<style scoped>

</style>