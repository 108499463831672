<template>
  <!-- Dialog for "Reset Dashboards" -->
  <Dialog v-model:visible="resetDashboardDialog" :style="{width: '450px'}"  :modal="true">
    <template #header>
      <h5>{{ t('message.dashboard.confirm') }}</h5>
    </template>
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mke-mr-3" style="font-size: 2rem"/>
      <span>{{ t('message.dashboard.dashboardResetMessage') }}</span>
    </div>
    <template #footer>
      <Button :label="t('message.dashboard.reject')"  icon="pi pi-times" class="p-button-text" @click="resetDashboardDialog = false">
      </Button>
      <Button :label="t('message.dashboard.accept')" icon="pi pi-check" class="p-button-text" @click="resetDashboard">
      </Button>
    </template>
  </Dialog>


  <!-- Sidebar for Displays bigger than MD -->
  <mq-responsive target="lg+">
    <div id="sidebar">
      <div class="application-logo">
        <router-link to="/home">
          <div>
          <img class="logo_default" :src="require('@/assets/images/'+`${logo}`)"/>
          <img class="logo_light" :src="require('@/assets/images/'+`${logoLight}`)"  />
          </div>
        </router-link>
      </div>
      <TieredMenu :model="items"/>
    </div>
  </mq-responsive>

  <!-- Sidebar for Displays smaller than MD -->
  <mq-responsive target="lg-">
  <Sidebar id="sidebar" class="mobile" v-model:visible="mobileMenuVisible" :baseZIndex="1000">
      <div class="application-logo">
        <router-link to="/home">
            <img :src="require('@/assets/images/'+`${logo}`)"/>
        </router-link>
      </div>
      <PanelMenu :model="items" />
  </Sidebar>
</mq-responsive>

</template>

<script>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const visibleLeft = ref(true);
    const { t } = useI18n({ useScope: 'global' })
    const menu = ref();
    const store = useStore();
    const dashboard_menus = ref([]);
    const dashboards = computed(() => store.state.dashboard.dashboards)
    const logo = config.logo
    const logoLight = config.logoLight
    const resetDashboardDialog = ref(false);
    // const configuration_text = computed(() => t("message.dashboard.configuration"))
    const mobileMenuVisible = computed({
      get: () => {return store.state.dashboard.mobile_menu_visible},
      set: (value) => (store.commit("dashboard/set_mobile_menu_visible", value))
    })
    const items = ref([])
    const create_menu_items = async () => {
      dashboard_menus.value = []
      if (store.state.dashboard.dashboard_names){
        items.value = [
          {
            label: "Dashboards",
            icon: "mdi mdi-view-dashboard",
            class: "dashboards",
            command: () => {
              store.commit("dashboard/setModel", "dashboard_names")
              store.commit("dashboard/set_dashboard_visible", false)
              store.dispatch('dashboard/setMainView', 'dashboards')
            }
          },
        ]
      }
      if (store.state.dashboard.dashboard_categories){
        items.value.push(
            {
              label: t("message.dashboard.dashboardCategories"),
              icon: "mdi mdi-view-dashboard",
              class: "categories",
              // items: dashboard_menus.value
              command: () => {
                store.commit("dashboard/setModel", "dashboard_categories")
                store.commit("dashboard/set_dashboard_visible", false)
                store.dispatch('dashboard/setMainView', 'dashboard_categories')
              }
            }
        )
      }

      if (store.state.dashboard.datasource_config) {
        items.value.push(
            {
              label: t("message.dashboard.configuration"),
              icon: "mdi mdi-logout",
              command: () => {
                store.commit("dashboard/set_dashboard_visible", false)
                store.dispatch('dashboard/setMainView', 'config')
              }
            }
        )
      }
      if (store.state.dashboard.reset_dashboard) {
        items.value.push(
            {
              label:   t("message.dashboard.menu_widget_templates"),
              icon: "mdi mdi-logout",
              command: () => {
                store.commit("dashboard/set_dashboard_visible", false)
                store.commit("dashboard/setViewMode", "overview")
                store.commit("dashboard/setModel", "widget_templates")
                store.dispatch('dashboard/setMainView', 'widget_templates')
              }
            }
        )
      }
      if (store.state.dashboard.reset_dashboard) {
        items.value.push(
            {
              label:   t("message.dashboard.resetDashboard"),
              icon: "mdi mdi-close-octagon",
              command: () => {
                store.commit("dashboard/set_dashboard_visible", false)
                resetDashboardDialog.value = true
              }
            }
        )
      }
    }

    const resetDashboard = () => {
      store.commit('dashboard/reset_dashboard')
      resetDashboardDialog.value = false
      location.reload();
    };
    watch(dashboards, () => {
      create_menu_items()
    }, {deep: true})
    create_menu_items()
    const toggle = (event) => {
      menu.value.toggle(event);
    };

    return {items, menu, mobileMenuVisible, visibleLeft, toggle, resetDashboardDialog, resetDashboard,logo,logoLight, t};
  },
};
</script>
