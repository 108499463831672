<template>
  <div v-if="mobile" class="login" v-bind:class="[Interaction ? 'active' : 'inactive']">
    <mke-switch-theme-toggle class="theme__switcher"/>

    <!-- no interaction view -->
    <transition class="mke__login_transition_container" name="login-no-interaction-animation">
      <div
        style="height: 100vh;"
        v-show="!Interaction"
        v-bind:class="[visibleLeft ? 'sidebar-opened' : '']"
      >
        <div class="login-content mke-flex-centered" align="top">
          <div
            class="login-content-inner p-d-flex p-jc-center p-ai-center p-flex-column"
          >

            <img class="logo_default" :src="require('@/assets/images/'+`${logo}`)">
            <img class="logo_light" :src="require('@/assets/images/' + `${logoLight}`)">

          <div class="mke__touch_to_start p-text-center">
            <i class="mdi mdi-gesture-tap"></i>
            <div class="font-size-l mke-mt-2 mke-mb-0">{{ t('message.home.touchStart') }}</div>
            <div class="font-size-l mke-mt-2 mke-mb-0">Terminal ID: {{terminalUUID}}</div>
          </div>


          </div>
        </div>
      </div>
    </transition>


    <!-- interaction - show login-view -->
    <transition :duration="{ enter: 350, leave: 100 }" class="mke__login_transition_container" name="login-interaction-animation">
      <div
        style="height: 100vh;"
        v-show="Interaction"
        v-bind:class="[visibleLeft ? 'sidebar-opened' : '']"

      >
        <div class="login-content blur-small mke-flex-centered" align="top">
          <div
            class="login-content-inner p-d-flex p-jc-center p-ai-center p-flex-column"
          >
            <h3 class="mke__login_headline mke-mt-0 mke-mb-2">{{ t('message.home.login') }}</h3>
            <div class="mke__login_desc font-size-m mx-fg-text-medium mke-mb-8">
              {{ t('message.home.selectUser') }}
            </div>

            <div
              class="mke__login_waiter_list p-d-flex p-ai-stretch p-jc-stretch"
              style="width: 100%;"
            >
              <perfect-scrollbar
                ref="scroll"
                class="waiter-list p-flex-grow-1 mke-mb-3"
              >
                <div
                  class="waiter p-button-transparent p-ripple"
                  v-ripple
                  v-for="(user, index) in users"
                  v-bind:key="index"
                  @click="onSelectUser(user)"
                >
                  <Avatar class="mke-mr-3">{{
                  user.name.split(" ")
                      .map(([v]) => v)
                      .join("")
                }}
              </Avatar>
                  <div class="waiter-name">{{ user.name }}</div>
                </div>
              </perfect-scrollbar>
            </div>

            <!-- Change Password -->
            <mkeChangePasswordDialog v-if="showChangePasswordDialog" :noOldPassword="true" :userID="selectedUser.id"
                                     v-model:showDialog="showChangePasswordDialog"/>

            <!-- Enter Password -->
            <Dialog class="mke-dialog-enter-password" v-model:visible="showPasswordDialog" :dismissableMask="true"
                    :draggable="false" :closable=false :modal=true>
                <template #header>
                  <div>
                  <h5 class="p-d-inline-block mke-my-0 mke-mr-2">{{selectedUser.name}}</h5>
                  <span class="mx-fg-text-medium">{{ t('message.home.enterPassword') }}.</span>
                  </div>
                </template>
                <Password ref="passwordInputField" class="mke-login-password" v-model:visible="showPasswordDialog"
                          :feedback="false" toggleMask v-model="password" v-on:keyup.enter="enterButtonPressed"/>
            </Dialog>

            <mq-responsive target="lg+">
              <Sidebar class="keyboard" v-model:visible="showPasswordDialog" :modal=false :baseZIndex="700" position="bottom">
                <div id="keyboard-full" class="surface-medium-opaque mke-pt-2 mke-pb-2">
                  <mkeKeyboard layout="keyboard_full" store="loginPassword"
                               @enterButtonPressed="enterButtonPressed" @resetFocus="onResetFocus"/>
                </div>
              </Sidebar>
            </mq-responsive>

          </div>
        </div>
      </div>
    </transition>

    <!-- background-color - standard -->
    <div
      id="login-background"
      :style="Background"
      @click="Interaction = !Interaction, showPasswordDialog = false"
    >

    <!-- set/hide backgroundimage -->
    <!-- <div class="mke__backgroundimage">
      <div class="mke__overlay"></div>
      <img :src="require('@/assets/demo/background.jpg')">
    </div> -->

    <!-- set/hide backgroundvideo -->
      <div class="mke__backgroundvideo">
        <div class="mke__overlay"></div>
        <video-background
        :src="require('@/assets/demo/video.mp4')"
        style="max-height: 100vh; height: 100vh;"
        ></video-background>
      </div>
    </div>
  </div>
  <div v-else id="login" class="mx-grid">
    <div id="" class="mx-login-sidebar">
      <div class="mx-login-sidebar-inner">
        <div class="mx-login-sidebar-title mke-mb-8 fade-in-from-bottom delay-fast-3">
          <h1>{{ t('message.home.login') }}</h1>
        <div class="mx-fg-text-medium">{{ t('message.home.selectUser') }}</div>
        </div>
        <div class="mx-login-sidebar-form mke-my-3">
          <div class="mke__input-group fade-in-from-bottom delay-fast-4">
            <label for="User" class="mke__input-label">{{ t('message.home.labelUser') }}</label>
            <Dropdown v-model="selectedUser" :options="users" optionLabel="name" placeholder="Benutzer wählen…" class="p-dropdown-lg block">
              <template #value="slotProps">
                  <div v-if="slotProps.value" class="p-d-flex p-ai-center">
                      <i class="mx-icon mx-icon-user-circle mx-fg-primary font-size-m mke-mr-2"/>
                      <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                      {{ slotProps.placeholder }}
                  </span>
              </template>
              <template #option="slotProps">
                  <div v-if="slotProps.option" class="p-d-flex p-ai-center">
                      <i class="mx-icon mx-icon-user-circle mx-fg-text-high font-size-m mke-mr-2"/>
                      <div>{{ slotProps.option.name }}</div>
                  </div>
              </template>
            </Dropdown>
            <!-- <InputText class="p-inputtext-lg block"/> -->
          </div>
          <div class="mke__input-group fade-in-from-bottom delay-fast-5">
            <label for="Password" class="mke__input-label">{{ t('message.home.labelPassword') }}</label>
            <Password
              ref="passwordInputField"
              class="p-inputtext-lg block"
              v-model:visible="showPasswordDialog"
              :feedback="false"
              toggleMask
              v-model="password"
              v-on:keyup.enter="enterButtonPressed"
            />
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-jc-between mke-my-6 fade-in-from-bottom delay-fast-6">
          <div class="p-d-flex p-ai-center">
            <Checkbox id="stayLoggedIn" v-model="stayLoggedIn" inputId="stayLoggedIn" name="pizza" :binary="true" />
              <label for="stayLoggedIn" class="mke-ml-2">Eingeloggt bleiben</label>
          </div>
          <a class="bold mx-textlink" href="#">Passwort vergessen?</a>
        </div>
        <mke-button @click="enterButtonPressed()" class="block p-button-lg mke-mt-3 fade-in-from-bottom delay-fast-7" color="primary" label="Anmelden"/>
      </div>
      <!-- Footer -->
      <div class="mx-login-sidebar-footer mke-p-0">
        <div class="mx-login-sidebar-footer-left">Terminal ID: {{terminalUUID}}</div>
        <div class="mx-login-sidebar-footer-center"></div>
        <div class="mx-login-sidebar-footer-right">
        </div>
      </div>
    </div>
    <div id="" class="mx-login-background fade">
      <img :src="require('@/assets/demo/login-bg.png')">
    </div>
  </div>
</template>



<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import { useI18n } from 'vue-i18n'

export default {
  name: "Login",
  data() {
    return {
      Interaction: false,
      visibleLeft: false,
      Background: {
      },
    };
  },

  setup() {

    const mobile = ref(true)
    const stayLoggedIn = ref(false)
    const store = useStore();
    const logo = config.logo
    const logoLight = config.logoLight
    const password = computed({
      get: () => {
        return store.state.pos.keyboardDisplay["loginPassword"]
      },
      set: (val) => (
          store.commit("pos/setKeyboardDisplay", {keyboardDisplayId: "loginPassword", value: val})
      )
    })
    const { t } = useI18n({ useScope: 'global' })
    const showChangePasswordDialog = ref(false);
    const showPasswordDialog = ref(false);
    const selectedUser = ref(false);
    store.dispatch('general/getLoginUsers')
    const users = computed(() => store.state.general.loginUsers);
    const terminalUUID = computed(() => store.state.general.terminalUUID);
    const passwordInputField = ref(null)
    const keyboardFullLayoutComponents = computed(() => store.getters["layout/getSpecificLayout"]("keyboard_full"))

    /**
     * Login the selected user
     * @param user The selected user for the login
     */
    const buttonLoginUser = async (user) => {
      if (user == null) {
        return false;
      }
      await store.dispatch('general/login', {
        "user": user,
        "password": store.getters["pos/getKeyboardDisplay"]("loginPassword")
      })
    };

    /**
     * Called if a user was selected. Shows the password dialog if necessary
     */
    const onSelectUser = async (user) => {
      selectedUser.value = user;
      if (user.need_password) {
        showChangePasswordDialog.value = true
      } else {
        if(user.authentication_method){
          showPasswordDialog.value = true;
        } else {
          showPasswordDialog.value = false
          await buttonLoginUser(user)
        }
      }
    };

    /**
     * Emitted enter press of the keyboard: Try to login the selected user.
     */
    const enterButtonPressed = async () => {
      await buttonLoginUser(selectedUser.value)
      onResetFocus()
    }

    /**
     * Set the focus on the input of the password input
     */
    function onResetFocus() {
      passwordInputField.value.$el.children[0].focus()
    }

    return {
      buttonLoginUser,
      keyboardFullLayoutComponents,
      enterButtonPressed,
      password,
      showChangePasswordDialog,
      showPasswordDialog,
      onSelectUser,
      onResetFocus,
      passwordInputField,
      selectedUser,
      terminalUUID,
      users,
      t,
      mobile,
      stayLoggedIn,
      logo,
      logoLight,
    };
  },
};
</script>

<style lang="scss" scoped>
#login {
  overflow: hidden;
}
.mx-login-background {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform:translateX(10%);
    transition: all .5s cubic-bezier(0.4,0,0.2,1);
  }
}
  .mx-login-sidebar {
    width: 40vw;
    padding: 7.5vw 7.5vw 10px 7.5vw;
    background: var(--surface-high);
    z-index: 1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    transition: transform .3s cubic-bezier(0.4,0,0.2,1);
  }

  .mx-login-sidebar-footer {
    display: flex;
    align-items: center;
    flex-flow: row;
    justify-content: space-between;
    opacity: .35;
  }

  .switch-app-enter-active {
    .mx-login-sidebar {
      transform: translateX(-100%);
    }
    .mx-login-background img {
      transform: translateX(0%);
    }
  }
</style>
