<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0">
      <MultiSelect :filter="true" scrollHeight="400" :id="id" :modelValue="modelValue" :options="options"
                   optionLabel="name" optionValue="id" :display="displayType"
                   @change="$emit('update:modelValue', $event.value)"
                   class="block">
      </MultiSelect>
    </div>
  </div>
</template>

<script>


/**
 * A component to select multiple options like allergens in products
 *
 * @vue-prop {string} id - The id for the field
 * @vue-prop {string} displayType - the manner of presentation. More information: https://primevue.org/multiselect/
 * @vue-prop {string} label - The label for the field
 * @vue-prop {*} modelValue - The selected values to display
 * @vue-prop {Array.<Object>} options - The available entries for the multiselect field.
 * @vue-prop {boolean} readonly - Determines if the field is readonly
 *
 * @emits update:modelValue - Emitted when the component's modelValue or the corresponding translation is updated.
 */
export default {
  name: "mkeFormBuilderMultiSelect",
  props: {
    "id": String,
    "displayType": String,
    "label": String,
    "modelValue": null,
    "options": Array,
    "readonly": Boolean
  },
}
</script>

<style scoped>

</style>