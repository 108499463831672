<template>
  <div class="mke-dsh-widget" v-bind:class="[widget_value.loading ? 'loading' : '']">
    <div class="grid-stack-item">
      <mkePreloader v-show="widget_value.loading"/>
    </div>
    <Card class="grid-stack-item-content">
      <template #header>
        <div class="p-d-flex p-jc-between relative">
          <!-- menu button -->
          <div>
          <h2 v-if="title" class="display-micro">{{ title }}</h2>
          <h3 v-if="subtitle" class="body-14-regular">{{ subtitle }}</h3>
          </div>
          <mke-context-menu v-bind:widget_id="id"/>
          <!-- TODO: Widget Context Menu -->
          <!-- <Button
            class="p-button-transparent p-button-rounded context-menu"
            type="button"
            icon="mdi mdi-dots-vertical"
            @click="toggle"
          />
          <Menu ref="menu" :model="items" :popup="true" /> -->
        </div>
      </template>
      <template #content>
        <div v-if="widget_value.error == false" style="width: 100%; height: 100%">
          <v-chart
              class="chart"
              :id="id"
              :option="option"
              :theme="activeTheme"
              autoresize
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>

import {computed, watch, ref, getCurrentInstance} from "vue";
import {useStore} from "vuex";
import mkeContextMenu from "./components/mkeContextMenu";
import * as echarts from "echarts";

export default {
  name: "GraphPie",
  props: ["id", "widget_id", "title", "subtitle"],
  setup(props) {
    const store = useStore()
    const activeTheme = computed(() => store.state.general.activeTheme)
    const widget_value = computed(() => store.getters["dashboard/get_widget_info"](props.id))
    const series = ref([])
    const app = getCurrentInstance()
    const FormatValue = app.appContext.config.globalProperties.$filters

    const option = ref({
      legend: {
        type: 'scroll',
        orient: 'vertical',
        left: 0,
        top: 0,
        formatter: function (name) {
          return FormatValue.toDate(name)
        },
        selector: [{
          type: 'all',
          title: 'Alle'
        },
          {
            type: 'inverse',
            title: 'Inv'
          }]
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
        valueFormatter: (value) => FormatValue.toNumber(value),
      },
      label: {
        color: '#fcfcfc',
        fontSize: 18,
        textShadowColor: 'rgba(0,0,0,0.75)',
        textShadowBlur: 5,
        textShadowOffsetX: 1,
        textShadowOffsetY: 1,

        textBorderColor: 'rgba(0,0,0,0.5)',
        textBorderWidth: 1,
        formatter: function (value,) {
          return FormatValue.toDate(value.data.name)
        }
      },
      labelLine: {
        show: false
      },
      // emphasis: {
      //   itemStyle: {
      //     shadowBlur: 16,
      //     shadowOffsetX: 0,
      //     shadowColor: 'rgba(26, 27, 39, 0.8)'
      //   }
      // },
          toolbox: {
            left: 'center',
            itemSize: 25,
            top: 20,
            feature: {
              saveAsImage: {
                backgroundColor:'black'
              },
            }
          },
      series: series
    });
    watch(widget_value, () => {
      if (widget_value.value.value_type != undefined && widget_value.value.value_type === 'pie') {
        series.value = widget_value.value.value['series']
        try {
          let myChart = echarts.getInstanceByDom(document.getElementById(props.id))
          myChart.setOption(option.value, {notMerge: true})
          myChart.resize()
        } catch (err) {
          //Ignore if chart not inited
        }
      }
    }, {deep: true});
    return { activeTheme, option, mkeContextMenu, widget_value};
  },
};
</script>

