<template>
  <div id="pos-not-initialized" class="p-d-flex p-ai-center p-jc-center w-100 h-100">
    <div class="p-d-flex p-flex-column p-ai-center text-align-center gap-2">
      <i class="mdi mdi-information-outline display-tiny mx-fg-text-high p-mb-2"/>
      <div class="display-tiny">Sie haben noch kein Terminal für diesen Client ausgewählt!</div>
      <div class="font-size-s mx-fg-text-medium">Bitte wählen sie ein Terminal aus!</div>
      <div class="p-d-flex p-flex-row p-mt-5 gap-2">
        <Button
            v-for="terminal in terminals"
            :key="terminal.id"
            :label="terminal.name"
            :id="'select_pos_terminal_' + terminal.name.toLowerCase().replace(/ /g,'_')"
            class="p-button-default apply-close"
            @click="setTerminal(terminal)"
        />
      </div>

    </div>
  </div>
</template>

<script>
// import {computed} from "vue";
import {useStore} from "vuex";
import {useCookies} from "vue3-cookies";
import {computed, ref} from "vue";

export default {
  name: "PosSetTerminal",
  setup() {
    const store = useStore();
    const {cookies} = useCookies();
    const prefered_terminal = cookies.get('prefered_terminal')
    const activeTerminal = computed( () => store.getters["pos/getactiveTerminal"]());
    const terminals = ref()
    store.dispatch("pos/getTerminals", {
      successCallback: (data) => {
        terminals.value = data
        if(activeTerminal.value === null && prefered_terminal !== null && terminals.value.filter(terminals => terminals.id == prefered_terminal.id).length === 1) {
            store.commit("pos/setActiveTerminal", prefered_terminal)
        }
    }})
    const setTerminal = async (terminal_id) => {
      store.commit("pos/setActiveTerminal", terminal_id)
      cookies.set('prefered_terminal', terminal_id)
    }
    return {setTerminal, cookies, terminals};
  }
}
</script>

<style scoped>

</style>
