<template>
  <MxApp :app="app">
    <div id="admin" class="highlight-selected-menu-items" style="width: 100%; height: 100%;">
      <mkeSidebar :items="sidebarItems" :selectedItemId="'sidebar-item-' + model"/>
      <div id="content" class="has-sidebar">
        <MxTopBar class="fade"/>
        <mkeModelOverview v-if="viewMode == 'overview'" store="admin" :columns="shownSchema" :model="model" :actions="actionOptions"
                          @onResetPassword="onResetPassword($event)" @onRowClick="onRowClick($event)"/>
        <mkeModelDetail v-if="viewMode == 'detail'" :schema="shownSchema" store="admin" :model="model"
                        :activeID="activeID" v-on:modelValueUpdate="onModelValueUpdate" @onGoBack="onGoBack($event)"/>
      </div>
    </div>
  </MxApp>
</template>

<style lang="scss"></style>

<script>
import {computed, ref, watch} from "vue";
import { useStore } from "vuex";
import { getTranslatedSchema } from "../json/admin_demo.js"
import { useI18n } from 'vue-i18n'

export default {
  props: ["app", "id", "offset", "view", "modelName"],
  setup(props) {
    const store = useStore();
    store.commit("admin/setModel", props.modelName || "users")
    const activeID = ref(props.id || null)
    store.commit("admin/setViewMode", props.view || "overview")
    const { t } = useI18n({ useScope: 'global' })
    const model = computed(() => store.state.admin.model)
    const viewMode = computed(() => store.state.admin.viewMode);
    const translatedSchema = computed(() => getTranslatedSchema(model.value, viewMode.value))
    const shownSchema = ref(translatedSchema.value)
    const modelActions = ref({
      "users": [
        {"name": "Reset Password", "action": "onResetPassword"}
      ]
    })
    const actionOptions = ref(modelActions.value[model.value])

    function onGoBack() {
      if (!["terminal_settings", "system_settings"].includes(model.value)) {
        store.commit("admin/setViewMode", "overview")
      }
    }
    /**
     * Reset the password for the given users.
     * Called by the model actions given to the mkeModelOverview.
     * @param users The users to reset the password for
     */
    function onResetPassword(users) {
      for (let userID of users.map(x => x.id)) {
        store.dispatch("admin/resetPassword", userID)
      }
    }

    // /**
    //  * Update the url query data before each update
    //  */
    // onBeforeUpdate(() => {
    //   let queryData = JSON.parse(JSON.stringify(router.currentRoute.value.query))
    //   queryData.id = store.state.admin.activeID
    //   Remove id from the url if not needed or empty
    //   if (viewMode.value == "overview" || !queryData.id){
    //     delete queryData.id
    //   }
    //   /**
    //    * Remove url values if a sidebar item was clicked
    //    */
    //   if (resetUrl.value){
    //     delete queryData.limit
    //     delete queryData.offset
    //     delete queryData.order
    //     delete queryData.sort
    //     resetUrl.value = false
    //   }
    //   queryData.model = model.value
    //   queryData.view = viewMode.value
    //   router.push({query: queryData})
    // })
    const onRowClick = (clickedData) => {
      if (clickedData){
        activeID.value = clickedData.id
        if (model.value == 'devices'){
          store.dispatch("admin/getDeviceConfigLayout",
              {deviceId: clickedData.id, model: "devices", viewMode: "detail",
                onSuccessCallback: (layoutData) => {
                shownSchema.value = translatedSchema.value.concat(layoutData.layout)
          }});
        }
      } else {
        activeID.value = null
      }
    };


    /**
     * Load new possible actions
     */
    watch(model, () => {
      actionOptions.value = modelActions.value[model.value]
    })

    watch(translatedSchema, () => {
      shownSchema.value = [...translatedSchema.value]
    })

    /**
     * Triggered on model value update.
     */
    function onModelValueUpdate(field, value) {
      if (viewMode.value == 'detail' && model.value == "devices" && "device_type_id" == field) {
        store.dispatch("admin/getSpecificData",{id: value, model: "device_types",
          onSuccessCallback: (newData) => {
            let configuration = newData.configuration_parsed.replace(/'/g, '"')
            configuration = JSON.parse(configuration)
            shownSchema.value = translatedSchema.value.concat(configuration)
          }});
      }
    }

    const sidebarItems = ref([
      {
        id: "sidebar-item-users",
        label: t("message.home.users"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "users")
        }
      },
      {
        id: "sidebar-item-privileges",
        label: t("message.admin.privileges"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "privileges")
        }
      },
      {
        id: "sidebar-item-devices",
        label: t("message.admin.devices"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "devices")
        }
      },
      {
        id: "sidebar-item-authenticationMedia",
        label: t("message.admin.authenticationMedia"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "authentication_media")
        }
      },
      // {
      //   id: "sidebar-item-modules",
      //   label: "Modules",
      //   icon: "mdi mdi-view-dashboard",
      //   command: () => {
      //     store.commit("admin/setViewMode", "overview")
      //     store.commit("admin/setModel", "modules")
      //   }
      // },
      {
        id: "sidebar-item-languages",
        label: t("message.admin.languages"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "languages")
        }
      },
      {
        id: "sidebar-item-currencies",
        label: t("message.admin.currencies"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "currencies")
        }
      },
      {
        id: "sidebar-item-posTerminals",
        label: t("message.admin.posTerminals"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "pos_terminals")
        }
      },
      {
        id: "sidebar-item-terminalSettings",
        label: t("message.admin.terminalSettings"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "detail")
          store.commit("admin/setModel", "terminal_settings")
          activeID.value = store.state.general.terminalUUID
        }
      },
      {
        id: "sidebar-item-microservices",
        label: t("message.admin.microservices"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "microservices")
        }
      },
      {
        id: "sidebar-item-translations",
        label: t("message.admin.translations"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "translations")
        }
      },
      {
        id: "sidebar-item-system_settings",
        label: t("message.admin.system_settings"),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "detail")
          store.commit("admin/setModel", "system_settings")
          activeID.value = store.state.general.system_settings.id
        }
      },
    ])

    return {
      actionOptions,
      activeID,
      model,
      onGoBack,
      onModelValueUpdate,
      onResetPassword,
      onRowClick,
      viewMode,
      sidebarItems,
      shownSchema
    };
  },
};
</script>

<style lang="scss">
#dashboard {
  .mke-logout {
    margin-top: 60px;
  }
}
</style>
