<template>
  <div class="mke-dsh-widget" v-bind:class="[widget_value.loading ? 'loading' : '']">
    <div class="grid-stack-item">
      <mkePreloader v-show="widget_value.loading"/>
    </div>
    <Card class="grid-stack-item-content">
      <template #header>
        <div class="p-d-flex p-jc-between relative">
          <!-- menu button -->
          <div>
            <h2 v-if="title" class="display-micro">{{ title }}</h2>
            <h3 v-if="subtitle" class="body-14-regular">{{ subtitle }}</h3>
          </div>
          <mke-context-menu v-bind:widget_id="id"/>
          <!-- TODO: Widget Context Menu -->
          <!-- <Button
            class="p-button-transparent p-button-rounded context-menu"
            type="button"
            icon="mdi mdi-dots-vertical"
            @click="toggle"
          />
          <Menu ref="menu" :model="items" :popup="true" /> -->
        </div>
      </template>
      <template #content class="test">
        <div v-if="widget_value.error == false" style="width: 100%; height: 100%">
          <v-chart
            class="chart"
            :id="id"
            :option="option"
            :theme="activeTheme"
            autoresize
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import {computed, ref, watch, getCurrentInstance, reactive} from "vue";
import { useStore } from "vuex";
import * as echarts from 'echarts';
import mkeContextMenu from "./components/mkeContextMenu";
// import moment from "moment";
// import { graphic } from "echarts/core";

export default {
  name: "GraphArea",
  props: ["id", "widget_id", "title", "subtitle"],
  setup(props) {
    const store = useStore();
    const activeTheme = computed(() => store.state.general.activeTheme)
    const widget_value = computed(() =>
      store.getters["dashboard/get_widget_info"](props.id)
    );
    const xAxis = reactive({
      type: "category",
      offset: 10,
      boundaryGap: false,
      data: [],
      axisLabel: {
        formatter: function (value,) {
          return FormatValue.toDate(value)
        }
      },
    });
    const series = ref([]);
    watch(widget_value, () => {
      if (widget_value.value.value_type != undefined && widget_value.value.value_type === 'area'){
        xAxis.data = widget_value.value.value.xAxis.data;
        series.value = widget_value.value.value.series;
        try {
          let myChart = echarts.getInstanceByDom(document.getElementById(props.id))
          myChart.setOption(option.value, {notMerge: true})
          myChart.resize()
        } catch (err) {
          //Ignore if chart not inited
        }
      }
    }, {deep: true});
    const app = getCurrentInstance()
    const FormatValue = app.appContext.config.globalProperties.$filters
    const option = ref({
      grid: {
        top: 60,
        left: 10,
        right: 15,
        bottom: 40,
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        formatter: (params) => {
          let tooltip = params[0].axisValue + "<br>"
          params.forEach((value) => {
            if(value.data != 0) {
              tooltip = tooltip + value.marker + value.seriesName + ": " + value.data.toFixed(2) + "<br>"
            }
          })
          return tooltip
        },
      },
      toolbox: {
        left: 'center',
        itemSize: 25,
        top: 20,
        feature: {
          saveAsImage: {
            backgroundColor:'black'
          },
          dataZoom: {
            yAxisIndex: 'none'
          },
          magicType: {
            type: ['bar', 'line', 'stack']
          },
        }
      },
      xAxis: xAxis,
      yAxis: {
        type: "value",
        offset: 10,
        axisLabel: {
          formatter: function (value, ) {
            return FormatValue.toNumber(value);
          }
        },
        axisPointer: {
          snap: true,
        },
      },
      animationDuration: 1000,
      dataZoom: [
        {
          type: 'inside',
          throttle: 50
        }
      ],
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        right: 'auto',
        top: 0,
        selector: [{
          type: 'all',
          title: 'Alle'
        },
          {
            type: 'inverse',
            title: 'Inv'
          }]
      },
      series: series
    });

    return { activeTheme, option, widget_value, mkeContextMenu};
  },
};
</script>

