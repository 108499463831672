<template>
  <Dialog id="print-jobs-dialog" :modal="true" v-model:visible="printJobsDialogVisible" :style="{width: '80vw'}" :closable="false">
    <template #header>
      <h5>{{t('message.pos.printjobs.title')}}</h5>
    </template>

    <div class="p-d-flex" style="height:70vh">
      <div class="flex:1">
        <div class="w-100 mke-m-2" v-for="printJob in printJobStates" :key="printJob.id" >
          <button
              class="p-d-flex p-button"
              :class="getStateClass(printJob)"
              @click="showPrintJobContent(printJob)">
            Vorgang {{printJob.process_name}}: {{printJob.name}}/{{printJob.output_type}} --> {{printJob.printer_name}}:{{printJob.state}}
          </button>
        </div>
      </div>
      <div class="mx-print-preview" style="">
        <div v-if="selectedContent" v-safe-html="selectedContent" style="">
        </div>

      </div>
    </div>

    <template #footer>
      <mke-button id="button-print-jobs-close" label="Schliessen" @click="closeDialog" class="p-button-primary"/>
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "PosPrintJobsDialog",
  setup(){
    const store = useStore();
    const { t, n } = useI18n({ useScope: 'global' })

    const printJobsDialogVisible = computed( () =>
        store.state.pos.printJobStates !== undefined && store.state.pos.printJobStates !== null );

    const printJobStates = computed( () => store.state.pos.printJobStates );

    const getStateClass = (printJobState) => {
      const state = printJobState.state.toLowerCase()
      if( state === "printed" ) {
        return "p-button-primary";
      } else if( state === "failure" ) {
        return "p-button-danger";
      } else {
        return "p-button-warning";
      }
    }

    const selectedContent = ref(undefined);
    const showPrintJobContent = (printJob) => {
      console.log("showPrintJobContent", printJob);
      selectedContent.value = printJob.output_content;
    }

    const closeDialog = () => {
      store.commit('pos/setPrintJobStates', null);
      selectedContent.value = undefined;
    }

    return {
      t, n, printJobStates, printJobsDialogVisible, showPrintJobContent, getStateClass, selectedContent, closeDialog
    }
  },
}

</script>

<style scoped>

</style>