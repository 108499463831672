// initial state
import axios from "axios";
import i18n from '../../i18n/index.js'

const restUrlDatamanagementService = config.DataServiceUrl

function getDefaultState() {
    return {
        viewMode: 'overview',
        activeID: null,
        languages: {},
        model: null,
        countries: {},
        currencies: {},
        customers: {},
        devices: {},
        device_types: {},
        authentication_media: {},
        microservices: {},
        modules: {},
        options: {},
        privileges: {},
        products: {},
        product_categories: {},
        product_properties: {},
        product_property_categories: {},
        pos_terminals: {},
        terminal_settings: {},
        translations: {},
        users: {},
        vats: {},
        product_groups: {},
        function_groups: {},
        pos_navigations: {},
        print_layouts: {},
        print_rules: {},
        go_back_on_save: true,
        mobile_menu_visible: false,
        system_settings: {},
    }
}
const state= getDefaultState()

// getters
const getters = {
    /**
     * Get data from the story by the activeID
     */
    getData: (state) => (model, modelID) => {
        let res = {}
        try {
            res = state[model].items.find(x => x.id === modelID)
        } catch (err) {
            res = {}
        }
        return res
    },
    /**
     * Get data from the story by the activeID
     */
    getActiveData: (state) => () => {
        let res = {}
        if (state.activeID){
            try {
                res = state[state.model].items.find(x => x.id === state.activeID)
            } catch (err) {
                res = {}
            }
        }
        return res
    },
}

// actions
const actions = {
    changePassword({commit}, {newPassword, userID}) {
        axios.patch(restUrlDatamanagementService + "/users/changePassword", {
            id: userID,
            password: newPassword
        })
            .then(function (result) {
                commit("general/addLoginUser", result.data, {root: true})
                commit("general/addToast", {
                    detail: i18n.global.t('message.home.passwordChanged'),
                    life: 3000,
                    severity: "success",
                    summary: "Success"
                }, {root: true})
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
            });
    },
    /**
     * Create the new data
     */
    createData({commit}, {data, model, successCallback}) {
        axios.post(restUrlDatamanagementService + "/" + model, data)
            .then(function (result) {
                commit("addData", {model: model, data: result.data})
                commit("setActiveID", result.data.id)
                commit("general/addToast", {
                    detail: i18n.global.t('message.home.createSuccess'),
                    life: 3000,
                    severity: "success",
                    summary: i18n.global.t('message.home.dataCreated')
                }, {root: true})
                if( successCallback ) {
                    successCallback(result.data);
                }
            })
            .catch(function (error) {
                let detail = error.message
                if( error.response?.data?.detail )
                    detail = error.response.data.detail
                commit("general/addToast", {
                    detail: detail,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
            });
    },
    /**
     * Delete data by id
     */
    deleteData({commit}, {model, id}) {
        axios.delete(restUrlDatamanagementService + "/" + model + "/" + id)
            .then(function () {
                commit("deleteData", {model: model, id: id})
                commit("general/addToast", {
                    detail: i18n.global.t('message.home.DeleteSuccess'),
                    life: 3000,
                    severity: "success",
                    summary: i18n.global.t('message.home.dataDeleted'),
                }, {root: true})
            })
            .catch(function (error) {
                let detail = error.message
                if( error.response?.data?.detail )
                    detail = error.response.data.detail
                commit("general/addToast", {
                    detail: detail,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
            });
    },
    /**
     * Get all available data for the model from the service
     */
    getAllData({commit}, {field, model, filter=null, onSuccessCallback=null}) {
        let callUrl = restUrlDatamanagementService + "/" + model + "/"

        if( filter ) {
            const queryFilters = Object.entries(filter).map((entry) => {
                return "filter_" + entry[0] + "=" + entry[1];
            });

            if( queryFilters ) {
                callUrl += "?" + queryFilters.join("&");
            }
        }

        axios.get(callUrl)
            .then(function (result) {
                if (onSuccessCallback != null) {
                    onSuccessCallback(result.data)
                }
                commit("setOptions", {field: field, data: result.data})
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
                return []
            });
    },
    /**
     * Get all translations for the given object_id and model
     */
    getAllTranslationsForObject({commit}, {field, object_id, translation_model, onSuccessCallback}) {
        let callUrl = restUrlDatamanagementService + "/translations?field_name=" + field + "&model_name=" + translation_model + "&object_id=" + object_id
        console.log("CALL URL:", callUrl)
        axios.get(callUrl)
            .then(function (result) {
                onSuccessCallback(result.data)
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
                return []
            });
    },
    saveAllTranslationsForObject({commit}, {field, object_id, translation_model, translations, onSuccessCallback}) {
        let callUrl = restUrlDatamanagementService + "/translations/postAllTranslationsForField"
        console.log("POST URLLLLL:", callUrl, translations)
        let data = {
            field_name: field,
            model_name: translation_model,
            object_id: object_id,
            translations: translations
        }
        console.log(data)
        axios.post(callUrl, data)
            .then(function () {
                onSuccessCallback()
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
                return []
            });
    },
    getDeviceConfigLayout({commit}, {deviceId, model, viewMode, onSuccessCallback}) {
        let callUrl = restUrlDatamanagementService + "/" + model + "/" + deviceId + "/layout/" + viewMode
        axios.get(callUrl)
            .then(function (result) {
                if (onSuccessCallback) {
                    onSuccessCallback(result.data)
                }
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
                return []
            });
    },
    /**
     * Get all available data for the model from the service
     */
    getTreeData({commit}, {field, model, id, action, onSuccessCallback}) {
        let callUrl = restUrlDatamanagementService + "/" + model + "/" + id + "/" + action
        axios.get(callUrl)
            .then(function (result) {
                commit("setOptions", {field: field, data: result.data})
                if (onSuccessCallback) {
                    onSuccessCallback(result.data)
                }
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
                return []
            });
    },
    getSpecificData({commit}, {id, model, onSuccessCallback}) {
        let callUrl = restUrlDatamanagementService + "/" + model + "/" + id
        axios.get(callUrl)
            .then(function (result) {
                commit("addData", {model: model, data: result.data})
                if (onSuccessCallback) {
                    onSuccessCallback(result.data)
                }
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
                return []
            });
    },
    /**
     * Get new data from the service
     */
    retrieveData({commit}, {model, offset, limit, sort=null, order="asc", filter=null, successHandler = null, endpoint=null}) {
        let sortOrder = "asc"
        if (order === -1){
            sortOrder = "desc"
        }

        let callUrl = restUrlDatamanagementService + "/" + model + "/page?offset=" + offset + "&limit=" + limit  + "&order=" + sortOrder;
        if( endpoint ) {
            callUrl = restUrlDatamanagementService + "/" + endpoint + "?offset=" + offset + "&limit=" + limit  + "&order=" + sortOrder;
        }

        if( filter ) {
            let filterEntries = [];
            for(let f in filter ) {
                if( filter[f].value) {
                    filterEntries.push("filter_" + f + "=" + encodeURI(filter[f].value));
                } else if( typeof filter[f] === 'string' || filter[f] instanceof String ) {
                    filterEntries.push("filter_" + f + "=" + encodeURI(filter[f]));
                }
            }
            callUrl += "&" + filterEntries.join("&");
        }

        if (sort != null){
            callUrl += "&sort=" + sort
        }
        axios.get(callUrl)
            .then(function (result) {
                if( successHandler ) {
                    successHandler(result.data);
                }

            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
            });
    },

    getData({commit, dispatch}, {model, offset, limit, sort=null, order="asc", filter=null} ) {
        const retrieveParam = {
            model: model,
            offset: offset,
            limit: limit,
            sort: sort,
            order: order,
            filter: filter,
            successHandler: (data) => {
                data.sort = data.sort || sort;
                data.order = data.order || order;
                data.filter = data.filter || filter;
                commit("setData", {model: model, data: data})
            }
        }
        dispatch("retrieveData", retrieveParam);
    },
    /**
     * Reset the password for the given userId
     * @param userID ID of the user to reset the password for
     */
    resetPassword({commit}, userID) {
        axios.patch(restUrlDatamanagementService + "/users/resetPassword", {id: userID})
            .then(function () {
                commit("general/addToast", {
                    detail: i18n.global.t('message.home.resetSuccess'),
                    life: 3000,
                    severity: "success",
                    summary: i18n.global.t('message.home.resetSuccess')
                }, {root: true})
            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
            });
    },
    /**
     * Send the new data to the service
     */
    saveData({commit}, {data, model, successCallback}) {
        axios.put(restUrlDatamanagementService + "/" + model + "/" + data.id, data)
            .then(function (result) {
                commit("addData", {model: model, data: result.data})
                commit("general/addToast", {
                    detail: i18n.global.t('message.home.saveSuccess'),
                    life: 3000,
                    severity: "success",
                    summary: i18n.global.t('message.home.dataSaved')
                }, {root: true})
                if( successCallback ) {
                    successCallback(result.data);
                }
            })
            .catch(function (error) {
                let detail = error.message
                if( error.response?.data?.detail )
                    detail = error.response.data.detail
                commit("general/addToast", {
                    detail: detail,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
            });
    },
    /**
     * Send the new data to the service
     */
    saveAdditionalData({commit}, {uri, data, successCallback}) {
        axios.put(restUrlDatamanagementService + "/" + uri, data)
            .then(function (result) {
                commit("general/addToast", {
                    detail: i18n.global.t('message.home.saveSuccess'),
                    life: 3000,
                    severity: "success",
                    summary: i18n.global.t('message.home.dataSaved')
                }, {root: true});

                if( successCallback ) {
                    successCallback(result.data);
                }
            })
            .catch(function (error) {
                let detail = error.message
                if( error.response?.data?.detail )
                    detail = error.response.data.detail
                commit("general/addToast", {
                    detail: detail,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
            });
    }
}

// mutations
const mutations = {
    addData(state, {model, data}) {
        if (!state[model].items) {
            state[model].items = []
        }
        const index = state[model].items.findIndex((el) => el.id === data.id)
        if (index >= 0){
            state[model].items[index] = data
        } else {
            state[model].items.push(data)
        }

    },
    deleteData(state, {model, id}) {
        state[model].items = state[model].items.filter(x => x.id !== id)
    },
    setActiveID(state, activeID) {
        state.activeID = activeID
    },
    setData(state, {model, data}) {
        state[model] = data
    },
    setOptions(state, {field, data}) {
        state.options[field] = data
    },
    setViewMode(state, viewMode) {
        state.viewMode = viewMode
    },
    setModel(state, model) {
        state.model = model
    },
    set_mobile_menu_visible(state, visible){
        state.mobile_menu_visible = visible
    },
    set_go_back_on_save(state, flag) {
        state.go_back_on_save = flag;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    getDefaultState,
    actions,
    mutations
}
