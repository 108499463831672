<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0">
      <InputNumber
          :id="id"
          v-model="value"
          @input="$emit('update:modelValue', $event.value)"
          :placeholder="placeholder"
          :currency="currency"
          :min-fraction-digits="minFractionDigits"
          :max-fraction-digits="maxFractionDigits"
          :use-grouping="useGrouping"
          :mode="mode || 'decimal'"
          :disabled="readonly || false"
          class="block"
          :class="{'p-invalid':invalid}"
      >
      </InputNumber>
    </div>
  </div>
</template>

<script>
import {toRef} from "vue";

/**
 * An input field to show / edit a number.
 *
 * @vue-prop {string} currency - An ISO-4217 ISO-Code to set a currency for the field
 * @vue-prop {string} id - The id for the field
 * @vue-prop {string} invalid - See: https://primevue.org/inputnumber/
 * @vue-prop {string} label - The label for the field
 * @vue-prop {number} maxFractionDigits - Maximum decimal places
 * @vue-prop {number} minFractionDigits - Minimal decimal places
 * @vue-prop {string} mode - currency or decimal. See: https://primevue.org/inputnumber/
 * @vue-prop {*} modelValue - The selected value to display
 * @vue-prop {string} placeholder - A text if nothing is in the field
 * @vue-prop {boolean} readonly - Determines if the field is readonly
 * @vue-prop {boolean} useGrouping - Use grouping such as thousands separator See: https://primevue.org/inputnumber/
 *
 * @emits update:modelValue - Emitted when the component's modelValue is updated.
 */
export default {
  name: "mkeInput",
  props: {
    "currency": String,
    "id": String,
    "invalid": String,
    "label": String,
    "maxFractionDigits": Number,
    "minFractionDigits": Number,
    "mode": String,
    "modelValue": String,
    "placeholder": String,
    "readonly": Boolean,
    "useGrouping": Boolean
  },
  emits: ['update:modelValue'],
  setup(props){
    const value = toRef(props, 'modelValue');
    return { value }
  }
}
</script>

<style scoped>

</style>
