<template>
  <Dialog id="prn-formbuilder-print-layout-block-editor-dialog"
          :modal="true"
          :draggable="false"
          :closable="true"
          :visible="true"
          :dismissableMask="true"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
           :style="{width: '50vw'}"
          >
    <template #header>
      <h3 class="p-d-flex p-jc-between p-align-center w-100">{{block.name}}</h3>
    </template>

    <div class="p-d-flex">
      <div class="p-d-flex p-flex-column gap-2" style="flex:1">
        <div class="p-d-flex ">
          <label style="min-width: 10rem">Typ: </label>
          <Dropdown v-model="block.block_key" :options="blockDefinitions" optionValue="block_key" optionLabel="name" @change="changeType" >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>{{ slotProps.option.name }}</div>
                <small><em>{{slotProps.option.description}}</em></small>
              </div>
            </template>
          </Dropdown>
        </div>

        <div v-if="block.block_key === 'custom'" class="p-d-flex p-flex-column gap-2">
          <div>
            <label>{{t('message.fields.print_layout.label')}}</label>
            <mke-input-text v-model="block.name"/>
          </div>
          <Textarea v-model="block.jinja_code" rows="20" cols="30" />
          <mke-button label="Vorschau" @click="changeOption"/>
        </div>

        <div v-else v-for="(blockOption, optionIndex) in blockDefinition.options" :key="blockOption.option_key" class="p-d-flex">
          <label style="min-width: 10rem">{{blockOption.name}}: </label>
          <Dropdown v-model="block.values[optionIndex]" :options="blockOption.values" optionValue="value" optionLabel="name" @change="changeOption">
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>{{ slotProps.option.name }}</div>
                <small><em>{{slotProps.option.description}}</em></small>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="p-d-flex p-flex-column mx-print-preview" >
        <div v-if="preview" v-safe-html="preview" style="">
        </div>
      </div>
    </div>


    <template #footer>
      <mke-button id="button-block-cancel" :label="t('message.masterdata.button.cancel')" icon="pi pi-times" class="p-button-transparent" @click="closeDialog" />
      <mke-button id="button-block-commit" :label="t('message.masterdata.button.accept')" icon="pi pi-check" class="p-button-primary" @click="commitBlock" />
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import deepClone from "@/utilities";
import {useI18n} from "vue-i18n";
import {usePrintLayoutStore} from "@/store/pinia/print_layout/PrintLayoutStore";
import {storeToRefs} from "pinia";

export default {
  name: "PrnFormBuilderPrintLayoutBlockEditorDialog",
  props: ["mode", "item", "blocks"],
  emits: ["onItemAddCommitted", "onItemUpdateCommitted", "onItemAddCanceled", "onItemEditCanceled"],

  setup(props, context){
    const store = useStore();

    const { t, n } = useI18n({ useScope: 'global' });

    const printLayoutStore = usePrintLayoutStore();

    const {
      blockDefinitions
    } = storeToRefs(printLayoutStore);

    const block = ref( deepClone(props.item ?? printLayoutStore.createDefaultBlock()) );
    const blockDefinition = computed( () => printLayoutStore.getBlockDefinition(block.value.block_key) );

    const preview = ref("");

    const changeType = () => {
      block.value = printLayoutStore.synchBlock(blockDefinition.value, block.value, block.value.values);
      renderPreview();
    }

    const changeOption = () => {
      block.value = printLayoutStore.synchBlock(blockDefinition.value, block.value, block.value.values);
      renderPreview();
    }
    const renderPreview = () => {
      window.setTimeout(showPreview, 250);
    }

    onMounted(() => {
      console.log( "In edit-dialog: ", block);
      window.setTimeout(showPreview, 250);
    });

    const closeDialog = () => {
      store.commit("general/addToast", {
        detail: t('message.masterdata.toast.cancel'),
        life: 3000,
        severity: "info",
        summary: "Info"
      } );

      if( props.mode === "add") {
        context.emit("onItemAddCanceled");
      } else {
        context.emit("onItemUpdateCanceled");

      }
    }

    const commitBlock = () => {
      const editedBlock = printLayoutStore.synchBlock(blockDefinition.value, block.value, block.value.values)
      if( props.mode === "add") {
        context.emit("onItemAddCommitted", [editedBlock]);
      } else {
        context.emit("onItemUpdateCommitted", [editedBlock]);
      }
    }

    const showPreview = () => {
      printLayoutStore.getPreview([block.value],
          (blocks, html) => {
            preview.value = html;
          },
          (error, html) => {
            preview.value = html;
          });
    }

    return {
      t, n, block, closeDialog, commitBlock, blockDefinitions, blockDefinition, printLayoutStore, preview, changeType, changeOption
    }
  }
}

</script>

<style scoped>

</style>
