<template>
  <MxApp :app="app">
    <div id="masterData" class="highlight-selected-menu-items"  style="width: 100%; height: 100%;">
      <div class="fade-in-from-left">
        <mkeSidebar :items="sidebarItems" selectedItemId="sidebar-item-products" class="fade-in-from-left" />
      </div>
      <div id="content" class="has-sidebar">
        <MxTopBar class="fade"/>
        <mkeModelOverview v-show="viewMode === 'overview'" store="admin" :columns="schema" :model="model"
                          class="fade-in-from-bottom"
                          @onRowClick="onRowClick"/>
        <mkeModelDetail v-if="viewMode === 'detail'" :schema="schema" store="admin" :model="model"
                        :activeID="activeID" @onGoBack="onGoBack($event)"/>
      </div>
    </div>
  </MxApp>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {getTranslatedSchema} from "../json/admin_demo.js";
import {useI18n} from 'vue-i18n'

export default {
  props: ["app"],
  setup() {
    const store = useStore();
    store.commit("admin/setModel", "products")
    store.commit("admin/setViewMode", "overview")
    store.commit("admin/set_go_back_on_save", false)
    const activeID = ref(null)
    const {t} = useI18n({useScope: 'global'})
    const model = computed(() => store.state.admin.model)
    const viewMode = computed(() => store.state.admin.viewMode);
    const schema = computed(() => getTranslatedSchema(model.value, viewMode.value))

    function onGoBack() {
      store.commit("admin/setViewMode", "overview")
    }

    const onRowClick = (clickedData) => {
      if( clickedData ) {
        activeID.value = clickedData.id
      } else {
        activeID.value = null;
      }
    };

    const sidebarItems = ref([
      {
        id: "sidebar-item-customers",
        label: t('message.masterdata.model.customers', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "customers")
          store.commit("admin/set_go_back_on_save", true)
        }
      },
      {
        id: "sidebar-item-products",
        label: t('message.masterdata.model.products', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "products")
          store.commit("admin/set_mobile_menu_visible", false)
          store.commit("admin/set_go_back_on_save", false)

        }
      },
      {
        id: "sidebar-item-product-categories",
        label: t('message.masterdata.model.product_categories', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "product_categories")
          store.commit("admin/set_mobile_menu_visible", false)
          store.commit("admin/set_go_back_on_save", true)

        }
      },
      {
        id: "sidebar-item-product-properties",
        label: t('message.masterdata.model.product_properties', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "product_properties")
          store.commit("admin/set_go_back_on_save", true)

        }
      },
      {
        id: "sidebar-item-product-property_categories",
        label: t('message.masterdata.model.product_property_categories', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "product_property_categories")
          store.commit("admin/set_go_back_on_save", true)

        }
      },
      {
        id: "sidebar-item-vats",
        label: t('message.masterdata.model.vats', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "vats")
          store.commit("admin/set_mobile_menu_visible", false)
          store.commit("admin/set_go_back_on_save", true)

        }
      },
      {
        id: "sidebar-item-product-group",
        label: t('message.masterdata.model.product_groups', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "product_groups")
          store.commit("admin/set_mobile_menu_visible", false)
          store.commit("admin/set_go_back_on_save", true)

        }
      },
      {
        id: "sidebar-item-countries",
        label: t('message.masterdata.model.countries', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "countries")
          store.commit("admin/set_go_back_on_save", true)

        }
      },
      {
        id: "sidebar-item-action-group",
        label: t('message.masterdata.model.function_groups', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "function_groups")
          store.commit("admin/set_mobile_menu_visible", false)
          store.commit("admin/set_go_back_on_save", true)

        }

      },
      {
        id: "sidebar-item-pos-navigation",
        label: t('message.masterdata.model.pos_navigations', 2),
        icon: "mdi mdi-view-dashboard",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "pos_navigations")
          store.commit("admin/set_mobile_menu_visible", false)
          store.commit("admin/set_go_back_on_save", true)

        }

      },
      {
        id: "sidebar-item-print-layouts",
        label: t('message.masterdata.model.print_layouts', 2),
        icon: "mdi mdi-newspaper-variant-outline",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "print_layouts")
          store.commit("admin/set_mobile_menu_visible", false)
          store.commit("admin/set_go_back_on_save", false)

        }

      },
      {
        id: "sidebar-item-print-rules",
        label: t('message.masterdata.model.print_rules', 2),
        icon: "mdi mdi-newspaper-variant-outline",
        command: () => {
          store.commit("admin/setViewMode", "overview")
          store.commit("admin/setModel", "print_rules")
          store.commit("admin/set_mobile_menu_visible", false)
          store.commit("admin/set_go_back_on_save", false)
        }

      },
    ])
    return {activeID, model, onGoBack, onRowClick, viewMode, schema, sidebarItems, t};
  },
};
</script>

<style lang="scss">
</style>
