import {defineStore} from "pinia";
import deepClone from "@/utilities";
import {ref} from "vue";
import axios from "axios";

/**
 * Handelt die Pflege von Blöcken innerhalb des PrintLayouts ab (quasi die wysiwyg Komponenten)
 */
export const usePrintLayoutStore = defineStore('printLayout', () => {

    // Die Definitionen der Blöcke, die wir unterstützen
    // TODO Überlegen ob die Definition nicht viel besser im Print-Service aufgehoben ist
    const blockDefinitions = ref([
        {
            "block_key": "header",
            "name": "Header",
            "description": "Kopfbereich des Ausdrucks",
            "jinja_name": "predefined.header",
            "jinja_code": "",
            "options": [{
                    "name": "Layout",
                    "option_key": "layout",
                    "values": [{
                            "name": "Firma zentriert",
                            "value": "with_company_centered",
                            "description": "Firmenanschrift",
                        }, {
                            "name": "Rechnung",
                            "value": "bill",
                            "description": "'Rechnung'",
                        }, {
                            "name": "Regelname",
                            "value": "rule_name",
                            "description": "Name der angewandten Regel, bspw. 'Getränke' oder 'Storno Speisen'",
                        }]
                }]
        }, {
            "block_key": "info",
            "name": "Vorgangs-Info",
            "description": "Info zum Vorgang",
            "jinja_name": "predefined.info",
            "jinja_code": "",
            "options": [{
                "name": "Layout",
                "option_key": "layout",
                "values": [{
                    "name": "Tabelle",
                    "value": "table",
                    "description": "horizontale Angabe",
                }, {
                    "name": "Formular",
                    "value": "form",
                    "description": "vertikale Angabe",
                }]
            }]
        }, {
            "block_key": "bon",
            "name": "Bon Einträge",
            "description": "Wie sollen die Bestellungen dargestellt werden",
            "jinja_name": "predefined.bon_entries",
            "jinja_code": "",
            "options": [{
                "name": "Layout",
                "option_key": "layout",
                "values": [{
                    "name": "Einfach",
                    "value": "simple",
                    "description": "Anzahl und Produktname",
                }, {
                    "name": "Einfach in fett",
                    "value": "simple_bold",
                    "description": "Anzahl und Produktname FETT gedruckt",
                }, {
                    "name": "Detailiert",
                    "value": "detail",
                    "description": "Anzahl und Produktname zzgl weiterer Infos, wie Preise und PLU",
                }]
            }]
        }, {
            "block_key": "bill_entries",
            "name": "Rechnungs-Positionen",
            "description": "Wie sollen die Rechnungspositionen dargestellt werden",
            "jinja_name": "predefined.bill_entries",
            "jinja_code": "",
            "layout_type": "bill",
            "options": [{
                "name": "Positionen",
                "option_key": "positions",
                "values": [{
                    "name": "Kompakt",
                    "value": "compact",
                    "description": "Anzahl, Produktname und Preise auf 1 Zeile",
                }, {
                    "name": "Detailiert",
                    "value": "detail",
                    "description": "Anzahl, Produktname und Preise auf 2 Zeilen",
                }]
            }]
        }, {
            "block_key": "bill_price",
            "name": "Zahlungs-Informationen",
            "description": "Wie sollen die Zahlungs-Informationen dargestellt werden",
            "jinja_name": "predefined.bill_sum",
            "jinja_code": "",
            "layout_type": "bill",
            "options": [{
                "name": "Preise",
                "option_key": "prices",
                "values": [{
                    "name": "Formular",
                    "value": "form",
                    "description": "vertikale Angabe",
                }, {
                    "name": "Tabelle",
                    "value": "table",
                    "description": "horizontale Angabe",
                }]
            }]
        }, {
            "block_key": "bill_vat",
            "name": "MwSt-Informationen",
            "description": "Wie sollen die MwSt-Informationen dargestellt werden",
            "jinja_name": "predefined.bill_vat",
            "jinja_code": "",
            "layout_type": "bill",
            "options": [{
                "name": "MWST",
                "option_key": "vat",
                "values": [{
                    "name": "Tabelle",
                    "value": "table",
                    "description": "horizontale Angabe",
                }, {
                    "name": "Detailiert",
                    "value": "detail",
                    "description": "In ganzen Sätzen",
                }]
            }]
        }, {
            "block_key": "hr",
            "name": "Trennstrich",
            "description": "Trennstrich",
            "jinja_name": "predefined.hr",
            "jinja_code": "",
            "options": []
        }, {
            "block_key": "cut",
            "name": "Paper-Cut",
            "description": "Papierschnitt",
            "jinja_name": "predefined.cut",
            "jinja_code": "",
            "options": []
        }, {
            "block_key": "br",
            "name": "Leerzeile",
            "description": "Leerzeile",
            "jinja_name": "predefined.br",
            "jinja_code": "",
            "options": []
        }, {
            "block_key": "custom",
            "name": "Eigenes Template",
            "description": "Erstellen Sie Ihr eigenes Template - wenn Sie wissen, was Sie tun ;)",
            "jinja_name": "custom",
            "jinja_code": "",
            "options": []
        }
    ]);

    console.log("PrintLayoutStore: ", blockDefinitions.value);

    const wysiwygDefinition = ref([]);
    const width = ref(40);

    const getBlockDefinition = (key) => {
        return blockDefinitions.value.find((b) => b.block_key === key);
    }
    
    const getSelectedBlockOptions = (blockDefinition, values) => {
        const options = values.map((value, index) => {
            if(blockDefinition.options[index]?.values) {
                return blockDefinition.options[index].values.find((o) => {
                    return o.value == value
                });
            } else {
                return null;
            }
        }).filter(v => v);
        return options;
    }

    const createBlock = (key, values) => {
        const blockDefinition = getBlockDefinition(key);
        const block = synchBlock(blockDefinition, {}, values);

        return block
    }

    const synchBlock = (blockDefinition, block, values) => {
        block.description = blockDefinition.description;
        block.block_key = blockDefinition.block_key;
        block.jinja_name = blockDefinition.jinja_name;
        block.label = blockDefinition.label;
        if( blockDefinition.block_key !== "custom" ) {
            block.name = blockDefinition.name;
        }
        block.layout_type = blockDefinition.layout_type ?? "bon";
        block.options = deepClone(blockDefinition.options)

        block.selected_options = getSelectedBlockOptions(blockDefinition, values);
        block.values = block.selected_options.map(o => o.value).filter(v=>v);
        if( blockDefinition.block_key !== "custom" ) {
            block.jinja_code = "";
            block.label = blockDefinition.name + ": " + block.selected_options.map((o) => o.description).join("; ");
        } else {
            block.label = "Eigenes Template: " + block.name
        }


        return block;
    }

    const addBlock = (key, values) => {
        const newBlock = createBlock(key, values);
        wysiwygDefinition.value.push(newBlock);

        newBlock.index = wysiwygDefinition.value.length;

        return newBlock;
    }

    const createDefaultBlock = () => {
        const defaultBlockKey = "br";

        const defaultBlockDefinition = getBlockDefinition(defaultBlockKey)
        const values = defaultBlockDefinition.options.map(blockOption => blockOption.values[0].value)

        const block = createBlock(defaultBlockKey, values);
        block.index = wysiwygDefinition.value.length + 1;

        return block;
    }

    const init = (currentDefinition, currentJinjaCode ) => {
        wysiwygDefinition.value = deepClone(currentDefinition ?? []);

        if( wysiwygDefinition.value.length === 0 && !currentJinjaCode) {
            wysiwygDefinition.value = []

            addBlock("header", ["with_company_centered"])
            addBlock("cut", [])
        }
    }

    const getPreview = (blocks, successCallback, errorCallback) => {
        const printServiceUrl = config.PrintServiceUrl;
        const layout = {
            width: width.value,
            blocks: blocks
        }
        const includesBillType = blocks.find(b => b.layout_type === "bill")
        const preview_data = includesBillType ? 'simple_bill' : 'simple_bon'
        axios.post(printServiceUrl + "/print_layout/preview/" + preview_data, layout)
            .then((result) => {
                if( result.data?.html ) {
                    const html = result.data.html;
                    const jinjaCode = result.data.jinja_code;
                    if( successCallback ) {
                        successCallback(blocks, html, jinjaCode);
                    }
                }
            }).catch(function (error) {
                let message = error.response && error.response.data && error.response.data.detail ? error.response.data.detail : error.message;
                if( errorCallback ) {
                    errorCallback(error, "<div>" + JSON.stringify(message) + "</div>");
                }
            });
    }


    return {
        init,
        blockDefinitions,
        wysiwygDefinition,
        createBlock,
        createDefaultBlock,
        getBlockDefinition,
        getSelectedBlockOptions,
        synchBlock,
        getPreview,
        width
        }
    }
);
