<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0">
      <InputSwitch
        v-model="value"
        @input="$emit('update:modelValue', $event)"
        :trueValue="true"
        :falseValue="false">
      </InputSwitch>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

/**
 * Select / Deselect a bool value.
 *
 * @vue-prop {string} id - The id for the field
 * @vue-prop {string} label - The label for the field
 * @vue-prop {*} modelValue - The selected value
 * @vue-prop {boolean} readonly - Determines if the field is readonly
 *
 * @emits update:modelValue - Emitted when the component's modelValue is updated.
 */
export default {
  name: "mkeFormBuilderInputSwitch",
  props: {
    "id": String,
    "label": String,
    "modelValue": String,
    "readonly": Boolean
  },
  emits: ["update:modelValue"],
  setup(props) {
    const value = ref(props.modelValue)
    return {value}
  }
}
</script>

<style scoped>

</style>