<template>
  <div class="dsh-calendar p-d-flex p-jc-between p-ai-center">
    <div>
        <Button class="p-button-raised p-button-text" @click="toggleCalendar">
          {{ dashboard_daterange }}
            <br>
            {{weekdaysShortcut}}
        </Button>
        <mq-responsive target="md-">
        <div
              v-if="dashboard_compare"
              class="dsh-text-compare mke-ml-2 mke-mt-1 body-14-regular mx-fg-text-medium nowrap"
              ><span v-if="dashboard_compare.id != 'no_compare'">{{ t('message.dashboard.comparisonWith') }}</span>
              {{ dashboard_compare.label }}
        </div>
        </mq-responsive>
    </div>

    <!-- OverlayPanel View -->

      <OverlayPanel
        ref="overlayPanel"
        appendTo="body"
        :showCloseIcon="false"
        id="overlay_panel"
        :style="{width: '60vw'}"
        :breakpoints="{ '640px': 'calc(100vw - 30px)' }"
      >
          <div class="p-d-flex p-flex-column p-flex-md-row gap-4">
            <div class="p-d-flex p-flex-column gap-4 flex-grow">
              <div class="time-period-dropdown-wrapper">
                <h6 class="mke-mb-4">Zeitraum</h6>
                <div class="p-d-flex p-ai-center gap-4">
                  <Dropdown
                    v-model="predefinedDate"
                    :options="predefinedDateOptions"
                    optionLabel="label"
                    :placeholder="t('message.dashboard.thisYear')"
                  >
                    <template #value="value">
                      <span v-if="value.value.id === 'thisYear'" >{{ t(value.placeholder) }}</span>
                    </template>
                  </Dropdown>
                  <span class="font-bold">{{localizeDateRange(dateRange)}}</span>
                  </div>
                  <div class="p-d-flex p-ai-center gap-4 mke-mt-2">
                    <div  class="time-weekdays-selector">
                      <MultiSelect  :options="weekdaysName" v-model="predefinedWeekdays" :disabled="predefinedDate.id ==='today' || predefinedDate.id ==='yesterday'"
                                   @change="selectedWeekdays($event)"
                                   optionLabel="name" v-bind:placeholder="t('message.dashboard.selectWeekDays')" />
                    </div>
                    </div>

              </div>
              <Calendar
                id="range"
                class="mke-mb-3"
                v-model=calendarDateRange
                :inline="true"
                :selectionMode="selectionMode"
                :manualInput="false"
                :yearNavigator="true"
                :monthNavigator="true"
                yearRange="1950:2030"
                @date-select="dateManual()"
              >
              </Calendar>
            </div>

            <mq-responsive target="md+">
              <Divider layout="vertical" />
            </mq-responsive>


            <div class="p-d-flex p-flex-column gap-4 flex-grow">
              <div class="compare-period-dropdown-wrapper">
                  <h6 class="mke-mb-4">{{ t('message.dashboard.comparison') }}</h6>

                  <div class="p-d-flex p-ai-center gap-4">
                    <Dropdown
                      class=""
                      v-model="selectedComparePeriod"
                      :options="comparePeriod"
                      optionLabel="label"
                      :placeholder="t('message.dashboard.noComparison')"
                    >
                      <template #value="value">
                        <span v-if="value.value.id === 'no_compare'">{{ value.placeholder }}</span>
                      </template>
                    </Dropdown>
                    <span class="font-bold">{{
                      localizeDateRange(dateRangeCompare)
                    }}</span>
                    </div>
                    <div class="p-d-flex p-ai-center gap-4 mke-mt-2">
                      <div class="compare-weekdays-selector">
                        <MultiSelect :options="weekdaysName"  v-model="weekdaysCompare" :disabled="selectedComparePeriod.id ==='no_compare'"
                                     @change="selectedWeekdaysCompare($event)"
                                     optionLabel="name" v-bind:placeholder="t('message.dashboard.selectWeekDays')" />
                      </div>

                    </div>
                </div>
              <Calendar
                id="range"
                class="mke-mb-3"
                v-model="calendarDateRangeCompare"
                :inline="true"
                :selectionMode="selectionMode"
                :manualInput="false"
                :yearNavigator="true"
                :monthNavigator="true"
                yearRange="1950:2030"
                @date-select="compareDateManual()"
                >
              </Calendar>
            </div>
          </div>

        <div class="p-d-flex p-ai-center p-jc-end mke-mt-3 apply-exit">
          <div class="p-d-flex p-jc-space-between">
            <Button
              :label="t('message.dashboard.exit')"
              class="p-button-raised p-button-text mke-mr-3"
              @click="toggleCalendar(false)"
            >
            </Button>
            <Button
              :label="t('message.dashboard.applyClose')"
              class="p-button-primary apply-close"
              @click="applyChanges()"
            >
            </Button>
          </div>
        </div>
      </OverlayPanel>


    <!-- Modal View -->

      <Dialog id="dsh-calendar-dialog" class="mke--dialog-fullscreen" v-model:visible="calendarDialogVisible" style="width: 100vw;">
        <div class="p-d-flex p-flex-column p-flex-md-row gap-4">
            <div class="p-d-flex p-flex-column gap-4 flex-grow">
              <div class="time-period-dropdown-wrapper">
                <h6 class="mke-mb-4">Zeitraum</h6>
                <div class="p-d-flex p-ai-center gap-4">
                  <Dropdown
                    v-model="predefinedDate"
                    :options="predefinedDateOptions"
                    optionLabel="label"
                    :placeholder="t('message.dashboard.thisYear')"
                  >
                    <!-- <template #value="value">
                      <span v-if="value.value.id === 'total'" >{{ t(value.placeholder) }}</span>
                    </template> -->
                  </Dropdown>
                  <span class="font-bold">{{
                    localizeDateRange(dateRange)
                  }}</span>

                </div>
                <div class="p-d-flex p-ai-center gap-4 mke-mt-2">
                  <div>
                    <MultiSelect :options="weekdaysName" v-model="predefinedWeekdays"
                                 @change="selectedWeekdays($event)"  :disabled="predefinedDate.id ==='today' || predefinedDate.id ==='yesterday'"
                                 optionLabel="name" v-bind:placeholder="t('message.dashboard.selectWeekDays')" />
                  </div>
                </div>
              </div>
              <Calendar
                  id="range"
                  class="mke-mb-3"
                  v-model=calendarDateRange
                  :inline="true"
                  :selectionMode="selectionMode"
                  :manualInput="false"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  yearRange="1950:2030"
                  @date-select="dateManual()"
              >
              </Calendar>
            </div>

            <mq-responsive target="md+">
              <Divider layout="vertical" />
            </mq-responsive>


            <div class="p-d-flex p-flex-column gap-4 flex-grow">
              <div class="compare-period-dropdown-wrapper">
                <div>
                  <h6 class="mke-mb-4">{{ t('message.dashboard.comparison') }}</h6>

                  <div class="p-d-flex p-ai-center gap-4">
                    <Dropdown
                      class=""
                      v-model="selectedComparePeriod"
                      :options="comparePeriod"
                      optionLabel="label"
                      :placeholder="t('message.dashboard.noComparison')"
                    >
                      <!-- <template #value="value">
                        <span v-if="value.value.id === 'no_compare'">{{ t(value.placeholder) }}</span>
                      </template> -->
                    </Dropdown>
                    <span class="font-bold">{{
                      localizeDateRange(dateRangeCompare)
                    }}</span>
                    </div>
                    <div class="p-d-flex p-ai-center gap-4 mke-mt-2">
                      <div>
                        <MultiSelect :options="weekdaysName"  v-model="weekdaysCompare" :disabled="selectedComparePeriod.id ==='no_compare'"
                                     @change="selectedWeekdaysCompare($event)"
                                     optionLabel="name" v-bind:placeholder="t('message.dashboard.selectWeekDays')" />
                      </div>
                    </div>

                </div>
              </div>
              <Calendar
                  id="range"
                  class="mke-mb-3"
                  v-model="calendarDateRangeCompare"
                  :inline="true"
                  :selectionMode="selectionMode"
                  :manualInput="false"
                  :yearNavigator="true"
                  :monthNavigator="true"
                  yearRange="1950:2030"
                  @date-select="compareDateManual()"
              >
              </Calendar>
            </div>
          </div>
          <template #footer>
            <div class="p-d-flex p-ai-center p-jc-end mke-mt-3">
          <div class="p-d-flex p-jc-space-between">
            <Button
              :label="t('message.dashboard.exit')"
              class="p-button-raised p-button-text mke-mr-3"
              @click="toggleCalendar(false)"
            >
            </Button>
            <Button
              :label="t('message.dashboard.applyClose')"
              class="p-button-primary"
              @click="applyChanges()"
            >
            </Button>
          </div>
        </div>
          </template>
      </Dialog>

    <mq-responsive target="lg+">
    <div
      v-if="dashboard_compare && dashboard_compare.id !='manual'"
      class="dsh-text-compare mke-ml-2 body-14-regular mx-fg-text-medium nowrap"
      ><span v-if="dashboard_compare.id != 'no_compare'">{{ t('message.dashboard.comparisonWith') }}</span>
      {{ dashboard_compare.label }}
      <br>
      {{weekdaysShortcutCompare}}
    </div>
      <div
          v-if="dashboard_compare && dashboard_compare.id == 'manual'"
          class="dsh-text-compare mke-ml-2 body-14-regular mx-fg-text-medium nowrap"
      ><span v-if="dashboard_compare.id != 'no_compare'">{{t('message.dashboard.comparison') + ':'}}</span>
        {{localizeDateRange(dateRangeCompare)}}
        <br>
        {{weekdaysShortcutCompare}}
      </div>
    </mq-responsive>
    <div class="mke-ml-8 p-d-flex p-ai-center">
      <InputSwitch v-model="dashboardStatusLive" /><span class="mke-pl-2"
        >{{ t('message.dashboard.liveDataEnabled') }}</span
      >
      <!-- TODO: Live-Daten aktiviert / deaktiviert -->
    </div>
  </div>
</template>



<style lang="scss">
.dsh-calendar .vc-container {
  width: 100% !important;
}

  .p-calendar {
    height: 100%;
  }
</style>


<script>
import {computed, ref, watch } from "vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'
import {CalendarDateOptions, getWeekDays} from "./date_options";
import { useMq } from "vue3-mq";
import deepClone from "@/utilities";

export default {
  setup() {
    const mq = useMq();

    const dashboardStatusLive = computed({
      get: () => {
        return store.state.dashboard.dashboards[store.state.dashboard.active_dashboard].dashboard_settings.live
      },
      set: (val) => (
          store.commit("dashboard/set_dashboard_live", {dashboardId: store.state.dashboard.active_dashboard, live: val}),
               store.dispatch('dashboard/calculate_dashboard')
      )
    })
    const weekdaysShortcutCompare = computed({
      get: () => {
        return store.getters["dashboard/get_weekdaysShortcut"](true)
      },
      set: () =>
      {
        weekdaysCompare.value =  []
        calendarDaysCompare.value = []
      }

    })
    const calendarDateRange = computed({
      get: () => {
        return selectionMode.value === "range" ? dateRange.value : predefinedCalendarDays.value
      },
      set: (val) =>
      {
        dateRange.value = val
      }
    })
    const calendarDateRangeCompare = computed({
      get: () => {
        return selectionMode.value === "range" ? dateRangeCompare.value : calendarDaysCompare.value
      },
      set: (val) =>
      {
        dateRangeCompare.value = val
      }
    })
    const store = useStore()
    const { t } = useI18n({ useScope: 'global' })
    const comparePeriod = ref([])
    const predefinedDateOptions = ref([])
    const DateOptions= ref([
      {label:t("message.dashboard.noComparison"), id: "no_compare", compare: true, compare_today: true, predefined:false},
      {label: t("message.dashboard.manually"), id: "manual", compare: true, compare_today: true, predefined:true},
      {label: t("message.dashboard.samePeriod"), id: "same_period", compare: true, compare_today: false, predefined:false},
      {label: t("message.dashboard.previousPeriod"), id: "previous_period", compare: true, compare_today: false, predefined:false},
      {label: t("message.dashboard.today"), id: "today", compare: false, compare_today: true, predefined:true},
      {label: t("message.dashboard.yesterday"), id: "yesterday", compare: false, compare_today: true, predefined:true},
      {label: t("message.dashboard.thisWeek"), id: "this_week",compare: false, compare_today: true, predefined:true},
      {label: t("message.dashboard.lastWeek"), id: "last_week", compare: true, compare_today: true, predefined:true},
      {label: t("message.dashboard.thisMonth"), id: "this_month", compare: false, compare_today: true, predefined:true},
      {label: t("message.dashboard.lastMonth"), id: "last_month", compare: false, compare_today: true, predefined:true},
      {label: t("message.dashboard.thisYear"), id: "this_year", compare: false, compare_today: true, predefined:true},
      {label: t("message.dashboard.lastYear"), id: "last_year", compare: false, compare_today: true, predefined:true},
      {label: t("message.dashboard.yearBefore"), id: "last_year_compare", compare: true, compare_today: false, predefined:false},
    ]);
    comparePeriod.value = DateOptions.value.filter(date => date.compare)
    predefinedDateOptions.value = DateOptions.value.filter(date => date.predefined)
    const weekdaysName = ref([
      {name: t("message.dashboard.calendar.monday"), key: 'Monday'},
      {name: t("message.dashboard.calendar.tuesday"), key:'Tuesday'},
      {name: t("message.dashboard.calendar.wednesday"), key: 'Wednesday'},
      {name: t("message.dashboard.calendar.thursday"), key: 'Thursday'},
      {name: t("message.dashboard.calendar.friday"), key: 'Friday'},
      {name: t("message.dashboard.calendar.saturday"), key: 'Saturday'},
      {name: t("message.dashboard.calendar.sunday"), key: 'Sunday'},
    ])
    const predefinedWeekdays = ref(store.getters["dashboard/get_weekdays"](false))
    const weekdaysCompare = ref(store.getters["dashboard/get_weekdays"](true))
    const weekdaysShortcut = computed(() => store.getters["dashboard/get_weekdaysShortcut"](false))
    const dateRange = ref(store.getters["dashboard/get_date_range"]())
    const predefinedDate = ref()
    const dateRangeCompare = ref(store.getters["dashboard/get_date_range_compare"]())
    const selectedComparePeriod = ref()
    const dashboard_daterange = ref()
    const predefinedCalendarDays = ref()
    const calendarDaysCompare = ref()
    const selectionMode = ref("range")
    dashboard_daterange.value = localizeDateRange(dateRange.value)

    const selectedWeekdays = () => {
      predefinedCalendarDays.value = getWeekDays(dateRange.value, predefinedWeekdays.value)
      if (selectedComparePeriod.value.id === 'no_compare')
        weekdaysCompare.value = []
      else
        weekdaysCompare.value = deepClone(predefinedWeekdays.value)
      predefinedCalendarDays.value.length === 0 ? selectionMode.value = "range" : selectionMode.value = "multiple"
      calendarDaysCompare.value =  getWeekDays(dateRangeCompare.value, weekdaysCompare.value)
    }
    const selectedWeekdaysCompare = () => {
      calendarDaysCompare.value =  getWeekDays(dateRangeCompare.value, weekdaysCompare.value)
    }
    const updateAllDate = () => {
      predefinedDate.value = store.state.dashboard.dashboards[store.state.dashboard.active_dashboard].dashboard_settings.predefined_date
      selectedComparePeriod.value = store.state.dashboard.dashboards[store.state.dashboard.active_dashboard].dashboard_settings.compare
      predefinedWeekdays.value= store.getters["dashboard/get_weekdays"](false)
      weekdaysCompare.value = store.getters["dashboard/get_weekdays"](true)
      if (predefinedDate.value.id != "manual") {
        dateRange.value = CalendarDateOptions().filter(option => option.id === predefinedDate.value.id)[0].range
      } else {
        dateRange.value = store.getters["dashboard/get_date_range"]()
      }
      predefinedCalendarDays.value = getWeekDays(dateRange.value, predefinedWeekdays.value)
      dateRangeCompare.value = store.getters["dashboard/get_date_range_compare"]()
      setCompareDateRangeByPeriod(selectedComparePeriod.value.id)
      dashboard_daterange.value = localizeDateRange(dateRange.value)
    }
    /** changing from one Dashboard to another
     * dashboard_daterange show the correct date for today
     * */
    store.subscribe(mutation => {
      if (overlayPanel.value) {
        if (!overlayPanel.value.visible && (mutation.type == "dashboard/set_main_view" || mutation.type == "dashboard/set_synch_state" ))
          updateAllDate()
      }
    });
    /**
     * Localize the dateRange
     * @param {Array} dateRangeToLocalize A date range to localize
     * @returns {string} The localized date range in the format: "from - to"
     */
    function localizeDateRange(dateRangeToLocalize){
      let result = "---"
      if (dateRangeToLocalize && dateRangeToLocalize.length > 0 && dateRangeToLocalize[0] != null){
        let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        result = new Date(dateRangeToLocalize[0]).toLocaleDateString('de-DE', options)
        if (dateRangeToLocalize[1]){
          result += ' - ' + new Date(dateRangeToLocalize[1]).toLocaleDateString('de-DE', options);
        }
      }
      return result
    }
    const dashboard_compare = computed(() =>
        store.state.dashboard.dashboards[store.state.dashboard.active_dashboard].dashboard_settings.compare
    );

    const overlayPanel = ref();
    const calendarDialogVisible = ref(false);

    // scroll to top after dialog is opened
    function calendarDialogContentScroll() {
      let calendarDialogContent = document.querySelector("#dsh-calendar-dialog .p-dialog-content");
      calendarDialogContent.scroll(0,0)
    }

     /**
     * Show / Show not the overlay panel
     * @param event The toggle event
     */
    const toggleCalendar = (event) => {
      updateAllDate()
      //show dialog on md- devices
      if ( mq.mdMinus == true ) {
        calendarDialogVisible.value = event;
        // scroll to top (bug-quickfix)
        setTimeout(calendarDialogContentScroll, 2);
      }
      // show overlaypanel on lg+ devices
      else {
        overlayPanel.value.toggle(event);
      }
    };
    const dateManual = () => {
      predefinedDate.value =
          {label: t("message.dashboard.manually"), id: "manual", compare: true, compare_today: true, predefined:true}
    }
    const compareDateManual = () => {
      selectedComparePeriod.value =
          {label: t("message.dashboard.manually"), id: "manual", compare: true, compare_today: true, predefined:true}
    }

    watch(predefinedDate, (_, oldPredefinedDate) => {
      predefinedCalendarDays.value.length  === 0 ? selectionMode.value = "range" : selectionMode.value = "multiple"
      if (oldPredefinedDate){
        predefinedWeekdays.value = weekdaysCompare.value = []
        selectionMode.value = "range"
        selectedComparePeriod.value = {
          label: t("message.dashboard.noComparison"),
          id: "no_compare",
          compare: true,
          compare_today: true,
          predefined: false
        }
      }
      if (predefinedDate.value.id == "manual") {
        comparePeriod.value = DateOptions.value.filter(date => date.compare)
        return
      }
      dateRange.value = CalendarDateOptions().filter(option => option.id === predefinedDate.value.id)[0].range
      if (predefinedDate.value.id === "today" || predefinedDate.value.id === "yesterday") {
        comparePeriod.value = DateOptions.value.filter(date => date.compare_today)
      } else {
        comparePeriod.value = DateOptions.value.filter(date => date.compare)
      }
      predefinedCalendarDays.value = getWeekDays(dateRange.value, predefinedWeekdays.value)
      setCompareDateRangeByPeriod(selectedComparePeriod.value.id)
    })
    /**
     * Set a predefined comparedate as the shown dateRangeCompare
     */
    watch(selectedComparePeriod, (_, oldSelectedComparePeriod) => {
      if (selectedComparePeriod.value.id == "manual") {
        return
      }
      if (oldSelectedComparePeriod && oldSelectedComparePeriod.id === 'no_compare'){
         weekdaysCompare.value = deepClone(predefinedWeekdays.value)
      }
      if (selectedComparePeriod.value.id === "no_compare"){
         weekdaysShortcutCompare.value = []
      }
      setCompareDateRangeByPeriod(selectedComparePeriod.value.id)
    })
    /**
     * Calculate and set the compare date range for the given comparePeriod
     * @param {string} comparePeriod The period to calculate/set a comparison date range
     */
    const setCompareDateRangeByPeriod = (comparePeriod) => {
      if (comparePeriod == "manual") {
        return
      }
      if(!dateRange.value || dateRange.value.length === 0){
        dateRangeCompare.value = undefined
        return
      }
      if (dateRange.value[1] != null) {
        dateRangeCompare.value = [new Date(JSON.parse(JSON.stringify(dateRange.value[0]))),
                                  new Date(JSON.parse(JSON.stringify(dateRange.value[1])))]
      } else{
        dateRangeCompare.value = [new Date(JSON.parse(JSON.stringify(dateRange.value[0]))),
                                  new Date(JSON.parse(JSON.stringify(dateRange.value[0])))]
      }
      let diffDays = 0
      switch(comparePeriod){
        case "same_period":
          break;
        case "previous_period":
          switch (predefinedDate.value.id){
            case "this_month": case "last_month" :
              // es wird zuerst das Enddatum auf das startdatum -1 gesetz (wenn im new Date  der Tag auf 0 gesetzt wird, ist es der Vortag des monats )
              // dann wird der erste tag des enddatums berechnet
              dateRangeCompare.value[1] = new Date(dateRangeCompare.value[0].getFullYear(), dateRangeCompare.value[0].getMonth(), 0);
              dateRangeCompare.value[0] = new Date(dateRangeCompare.value[1].getFullYear(), dateRangeCompare.value[1].getMonth(), 1);
              break
            case "this_year": case "last_year" :
              dateRangeCompare.value[0] = new Date(dateRangeCompare.value[0].setFullYear(dateRangeCompare.value[0].getFullYear() - 1));
              dateRangeCompare.value[1] = new Date(dateRangeCompare.value[1].setFullYear(dateRangeCompare.value[1].getFullYear() - 1));
              break
            case "manual": case "this_week": case "last_week":
              diffDays = dateRangeCompare.value[1].getDate() - dateRangeCompare.value[0].getDate()
              dateRangeCompare.value[1].setDate(dateRangeCompare.value[0].getDate() - 1);
              dateRangeCompare.value[0] = new Date(dateRangeCompare.value[1]);
              dateRangeCompare.value[0].setDate(dateRangeCompare.value[0].getDate() - diffDays);
              break
          }
          break;
        case "yesterday":case "this_week": case "last_week": case "this_month":case "last_month":case "this_year":case "last_year":
          dateRangeCompare.value = CalendarDateOptions().filter(option => option.id === selectedComparePeriod.value.id)[0].range
          break;
        case "last_year_compare":
          dateRangeCompare.value[0].setFullYear(dateRangeCompare.value[0].getFullYear()-1);
          dateRangeCompare.value[1].setFullYear(dateRangeCompare.value[1].getFullYear()-1);
          break;
        default:
          dateRangeCompare.value = undefined;
          return;
      }
      dateRangeCompare.value[0].setHours(0, 0, 0)
      dateRangeCompare.value[1].setHours(23, 59, 59)
      calendarDaysCompare.value =  getWeekDays(dateRangeCompare.value, weekdaysCompare.value)
    }

    /**
     * Apply the changes date/compare changes to the store, dispatch a recalculation of the dashboard
     * and toggle the overlay
     */
    const applyChanges = async () => {
      let dateRangeToStore = undefined
      if (dateRange.value){
        dateRangeToStore = [...dateRange.value]
        if (dateRangeToStore && dateRangeToStore.length > 0){
          if (!dateRangeToStore[1]){
            dateRangeToStore[1] = new Date(new Date(dateRangeToStore[0]).setHours(23, 59, 59))
          }
          else {
            dateRangeToStore[1] = new Date(new Date(dateRangeToStore[1]).setHours(23, 59, 59))
          }
        }
      }
      store.commit("dashboard/set_dashboard_date",
          {dashboardId: store.state.dashboard.active_dashboard, dateRange: dateRangeToStore})
      if (dateRangeCompare.value){
        let dateRangeCompareToStore = [...dateRangeCompare.value]
        if (dateRangeCompareToStore.length > 0){
          if (!dateRangeCompareToStore[1]){
            dateRangeCompareToStore[1] = new Date(new Date(dateRangeCompareToStore[0]).setHours(23, 59, 59))
          }
          else {
            dateRangeCompareToStore[1] = new Date(new Date(dateRangeCompareToStore[1]).setHours(23, 59, 59))
          }
        }
        store.commit("dashboard/set_dashboard_date_compare",
            {dashboardId: store.state.dashboard.active_dashboard, dateRangeCompare: dateRangeCompareToStore})
      }
      store.commit("dashboard/set_dashboard_compare",
          {dashboardId: store.state.dashboard.active_dashboard, compare: selectedComparePeriod.value})
      store.commit("dashboard/set_predefined_date",
          {dashboardId: store.state.dashboard.active_dashboard, predefined_date: predefinedDate.value})
      store.commit("dashboard/set_predefinedWeekdays",
          {dashboardId: store.state.dashboard.active_dashboard, weekdays: predefinedWeekdays.value})
      store.commit("dashboard/set_weekdaysCompare",
          {dashboardId: store.state.dashboard.active_dashboard, weekdays: weekdaysCompare.value})
      await store.dispatch('dashboard/calculate_dashboard')
      toggleCalendar(false)
    }
    return {
      comparePeriod,
      dashboard_compare,
      dateRange,
      dateRangeCompare,
      localizeDateRange,
      overlayPanel,
      predefinedDate,
      predefinedDateOptions,
      selectedComparePeriod,
      toggleCalendar,
      calendarDialogVisible,
      applyChanges,
      dateManual,
      compareDateManual,
      dashboard_daterange,
      dashboardStatusLive,
      updateAllDate,
      weekdaysName,
      predefinedWeekdays,
      weekdaysCompare,
      selectedWeekdays,
      weekdaysShortcut,
      weekdaysShortcutCompare,
      predefinedCalendarDays,
      selectedWeekdaysCompare,
      calendarDaysCompare,
      calendarDateRangeCompare,
      selectionMode,
      calendarDateRange,
      t
    }
  },
};
</script>
<!--                    TODO Timo class="special-day"-->
<style>
.special-day {
  /*background-color: darkblue;*/
  color: darkred;
}
</style>