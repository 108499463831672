<template>
  <div class="p-grid p-ai-center" v-if="modelValue">
    <label v-if="label" :class="labelClass">{{t(label)}}</label>
    <div :class="valueClass">{{modelValue}}</div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

/**
 * Component to display a value. This does not allow editing.
 *
 * @vue-prop {string} label - The label for the field
 * @vue-prop {string} labelClass - The css class name that gets added to the label
 * @vue-prop {*} modelValue - The value to display
 * @vue-prop {string} valueClass - The css class name that gets added to the modelValue
 */
export default {
  name: "mkeFormBuilderDisplayValue",
  props: {
    "label": String,
    "labelClass": String,
    "modelValue": null,
    "valueClass": String
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })

    return {t}
  }
}
</script>

<style scoped>

</style>