<template>
    <router-view v-slot="{ Component }">
      <transition name="switch-app" appear>
        <component :is="Component" :app="activeApp"/>
      </transition>
    </router-view>
    <Toast/>
</template>
<script>

import {computed, watch} from "vue";
import {useStore} from "vuex";
import {useToast} from "primevue/usetoast";
import { useI18n } from 'vue-i18n'
import dummy_data from "./json/dummy_data.json";
import router from "./router";
import { usePrimeVue } from "primevue/config";
import primeVueLocale from "@/i18n/primeVueLocale.json";
import { days } from "@/components/dashboard/date_options";
export default {
  setup(){
    const store = useStore()
    const toast = useToast()
    const primeVue = usePrimeVue();
    const activeApp = computed( () => store.state.general.activeApp)
    const { locale, t } = useI18n({ useScope: 'global' })
    /**
     * primevue locale
     */
    store.subscribe(async (mutation) => {
      if (mutation.type === "general/setActiveUser") {
        primeVue.config.locale = primeVueLocale[locale.value]
      }
      if (mutation.type === "general/setSystemSettings") {
        primeVue.config.locale.firstDayOfWeek = days[store.state.general.system_settings.first_day_of_week]
      }
    });

    // Check and register the terminal
    store.dispatch("general/checkAndRegisterTerminal", store.state.general.terminalUUID)

    if (store.state.general.activeUser) {
      store.commit("general/setActiveUser", store.state.general.activeUser)
      if(router.currentRoute.value.params.nextUrl) {
        router.push({path: router.currentRoute.value.params.nextUrl})
      } else {
        router.push({path: router.currentRoute.value.path})
      }
    }

    const toastsToShow = computed(() => store.state.general.toasts)
    // Load layouts here until a service handles it
    store.commit("layout/setLayout", dummy_data["layout"])

    /**
     * Add the toast to the view and clear it afterwards
     */
    watch(toastsToShow, () => {
      if (toastsToShow.value && toastsToShow.value.length > 0) {
        for (let toastToShow of toastsToShow.value) {
          toast.add({
            severity: toastToShow.severity,
            summary: toastToShow.summary,
            detail: toastToShow.detail,
            life: toastToShow.life
          });
        }
        store.commit("general/clearToasts")
      }
    }, {deep: true})
    const Favicon = config.favicon
    const  linkForFavicon = document.querySelector("link[rel~='icon']")
    linkForFavicon.setAttribute(`href`, `${Favicon}`);

    return {activeApp, locale, t}
  },
};
</script>

<style scoped>

</style>
