<template>
  <MxTopBar id="pos__topbar">
    <template #left>
      <div class="p-d-flex p-jc-between">
        <div v-if="activeProcess"
             class="mx-process-info"
             :data-process-name="activeProcess.name"
             :data-process-id="activeProcess.id">
          <h1 class="h6"
              @click="toggleProcessMeta($event)"
              aria-haspopup="true"
              aria-controls="process-meta">
            {{ activeProcess.name }}
          </h1>
          <Chip class=""
                :data-process-state="activeProcess.state"
                :label="activeProcess.state"/>
          <!-- overlay panel -->
          <OverlayPanel
              ref="overlayPanel"
              appendTo="body"
              :showCloseIcon="false"
              id="process-meta"
              :style="{ width: 'auto' }">

            <div class="mke-mb-1">
              <b>{{ t('message.pos.process_inhouse_type') }}:</b>
              &nbsp;{{ inhouse == true ? t('message.pos.process_inhouse') : t('message.pos.process_outhouse') }}
            </div>
            <div class="mke-mb-1">
              <b>{{t('message.pos.created_at')}}:</b>
              &nbsp;{{renderDate(activeProcess.create_date)}}, {{activeProcess.create_name}}
            </div>
            <div class="mke-mb-1">
              <b>{{t('message.pos.modified_at')}}:</b>
              &nbsp;{{renderDate(activeProcess.update_date)}}, {{activeProcess.update_name}}
            </div>
          </OverlayPanel>
          <!-- process menu -->
          <Button
              class="p-button-transparent context-menu"
              type="button"
              icon="mdi mdi-dots-vertical"
              @click="processMenu_toggle"
          />
          <Menu ref="processMenu" :model="processMenu_items" :popup="true">
            <template #item="{item}">
              <!-- if link/button -->
              <mke-button
                  v-if="item.type == 'link' && item.ifCondition"
                  :label="item.label"
                  :icon="item.icon"
                  :class="'p-menuitem-link block text-left w-100 font-size-xs ' + item.cssClass ?? ''"
                  :color="item.color"
                  v-model="item.action"
                  :functionCall="item.functionCall"
                  :functionCallStore="item.functionCallStore">
              </mke-button>
              <!-- if switch -->
              <div
                  v-if="item.type === 'switch'"
                  class="p-d-flex p-ai-center p-menuitem-link">
                <InputSwitch
                    :data-compressed="item.action"
                    :id="item.id"
                    class="mke-mr-2"
                    v-model="item.value"
                    @click="toggleInhouse"
                />
                <label :for="item.id">{{ item.label }}</label>
              </div>

              <!-- if toggle -->
              <div v-if="item.type === 'toggle'" class="p-d-flex p-ai-center">
                <ToggleButton
                    class="p-menuitem-link block text-left w-100"
                    @click="processMenu.hide()"
                    :id="item.id"
                    :data-inhouse="item.action"
                    v-model="item.action"
                    :onLabel="item.labelOn"
                    :offLabel="item.labelOff"
                    :onIcon="item.iconOn"
                    :offIcon="item.iconOff"/>
              </div>
            </template>
          </Menu>
        </div>
        <div v-else>
          &nbsp;
        </div>
        <Chip class="pos-terminal"
              :id="'pos-terminal-switch-' + activeTerminal.name.toLowerCase().replace(/ /g,'-')"
              :label="activeTerminal.name"
              @click="switchPosTerminal()"/>
        <Chip class="printer-state"
              :data-printer-state="printerState.state"
              icon = "mdi mdi-printer"
              @click="toggleShowPrinterJobState($event)"/>
      </div>
    </template>
  </MxTopBar>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {useCookies} from "vue3-cookies";

export default {
  name: "TopBar",
  setup() {
    const store = useStore();
    const { t,d } = useI18n({ useScope: "global" });
    const {cookies} = useCookies();
    const activeProcess = computed(() => {
      return store.state.pos.activeProcess;
    });

    const printerState = computed( () => store.state.pos.printerState );
    const printJobStates = computed( () => store.state.pos.printJobStates );
    const overlayPanel = ref();
    const activeTerminal = computed( () => store.getters["pos/getactiveTerminal"]());
    const toggleProcessMeta = (event) => {
      overlayPanel.value.toggle(event);
    };

    // eslint-disable-next-line no-unused-vars
    const toggleShowPrinterJobState = (event) => {
        if( printJobStates.value ) {
          store.commit('pos/setPrintJobStates', null);
        } else {
          store.dispatch('pos/getPrintJobStates');
        }
    };

    const switchPosTerminal = () => {
        cookies.remove('prefered_terminal');
        store.commit('pos/setActiveTerminal', null)
    };

    const inhouse = computed({
      get() {
        if (store.state.pos.activeProcess) {
          return store.state.pos.activeProcess.inhouse;
        } else {
          return undefined;
        }
      },
      set(inhouse) {
        store.dispatch("pos/updateProcess", {
          process: store.state.pos.activeProcess,
          values: { inhouse: inhouse },
        });
      },
    });

    const toggleInhouse = () => {
      inhouse.value = !inhouse.value;
    }

    // process context menu
    const processMenu = ref();
    const processMenu_toggle = (event) => {
      processMenu.value.toggle(event);
    };

    const processMenu_items = computed(() => {
      return [
        {
          id: "inhouse",
          type: "switch",
          label: t('message.pos.process_inhouse'),
          icon: "mdi mdi-cog",
          value: inhouse.value,
          action: toggleInhouse
        },
        {
          id: "void",
          type: "link",
          label: t('message.pos.void'),
          icon: "mdi mdi-trash-can",
          color: "danger",
          cssClass: 'void-process',
          ifCondition: activeProcess?.value?.state === 'opened',
          functionCall: "voidProcess",
          functionCallStore: "pos-main-keyboard-display",
        },
        {
          id: "history",
          type: "link",
          label: t('message.pos.process_history.show_history'),
          icon: "mdi mdi-history",
          ifCondition: true,
          functionCall: "showProcessHistory"
        },
        {
          id: "payments",
          type: "link",
          label: t('message.pos.payments'),
          icon: "mdi mdi-cash-register",
          ifCondition: activeProcess?.value?.payments?.length > 0,
          functionCall: "showPaymentsInfo"
        },
        {
          id: "signatures",
          type: "link",
          label: t('message.pos.signatures'),
          icon: "mdi mdi-certificate-outline",
          ifCondition: true,
          functionCall: "showProcessSignatures"
        },
      ]
    });

    const renderDate = (value) => {
     const date = moment(value, false);
     if( date.isValid() ) {
       const now = moment(new Date());
       let result = "";
       if( date.dayOfYear() === now.dayOfYear() ) {
         result = d(date.toDate(), 'time_short');
       } else {
         result = d(date.toDate(), 'datetime_short');
       }

       return result;
     } else {
       return value;
     }
    }

    return {
      activeProcess,
      overlayPanel,
      toggleProcessMeta,
      processMenu,
      processMenu_toggle,
      processMenu_items,
      inhouse,
      printerState,
      toggleShowPrinterJobState,
      switchPosTerminal,
      toggleInhouse,
      t,
      d,
      renderDate,
      activeTerminal
    };
  }
};
</script>
