<template>
  <div class="mke-dsh-widget table" v-bind:class="[data.loading ? 'loading' : '']">
    <div class="grid-stack-item">
      <mkePreloader v-show="data.loading"/>
    </div>
    <dsh_exportDataDialog v-model:visible="exportDataDialogVisible" :fileName="widget.title"  @fileNameExport="onfileNameExport"/>
    <Card class="grid-stack-item-content">
      <template #header>
        <div class="p-d-flex p-jc-between relative">
          <!-- menu button -->
          <div>
            <h2 v-if="widget.title" class="display-micro">{{ widget.title }}</h2>
            <h3 v-if="widget.subtitle" class="body-14-regular">{{ widget.subtitle }}</h3>
          </div>
          <mke-context-menu v-bind:widget_id="id"/>
        </div>
      </template>
      <template #content>
        <div v-if="data.error == false" style="width: 100%; height: 100%">
          <DataTable class="highlighted-on-hover" :value="data.value.data" ref="data_table" responsiveLayout="stack" :export-filename="fileName" breakpoint="960px"
                     :autoLayout="true" :paginator="true" :rows="10"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[10,20,50]"
                     :currentPageReportTemplate="t('message.dashboard.showing')+' '+`{first}`+' '+
                                                 t('message.dashboard.to')+' '+ `{last}`+' '+ t('message.dashboard.of')+' '+ `{totalRecords}`">
            <template #header>
              <div style="text-align: left">
                <Button icon="pi pi-external-link" label="Excel" @click="exportXLS($event)" />
                <Button icon="pi pi-external-link" label="CSV" @click="exportCSV($event)" />
              </div>
            </template>
            <Column v-for="col of data.value.columns" :key="col.field" :field="col.field" :sortable=true :header="col.header" :headerStyle="col.headerStyle" :headerClass="col.headerClass" :bodyStyle="col.bodyStyle">
              <template v-if="col.format==='text'" #body="{data}">
                {{data[col.field]}}
              </template>
              <template v-else-if="col.format==='number'" #body="{data}">
                {{$filters.toNumber(data[col.field]) +  ($filters.toCurrency(col.currency)  ? col.currency : '') }}
              </template>
              <template v-else-if="col.format==='number_diff'" #body="{data}">
                <span v-if="data[col.field] > 0" class="mx-fg-success-high" style="white-space: nowrap;">
                    {{$filters.toNumber(data[col.field]) +  ($filters.toCurrency(col.currency)  ? col.currency : '') }}
                    <i class="mdi mdi-menu-up"></i>
                </span>
                <span v-else-if="data[col.field] > -5" class="mx-fg-warning-high" style="white-space: nowrap;">
                    {{$filters.toNumber(data[col.field]) +  ($filters.toCurrency(col.currency)  ? col.currency : '') }}
                    <i class="mdi mdi-menu-right"></i>
                </span>
                <span v-else class="mx-fg-danger-high" style="white-space: nowrap;">
                    {{$filters.toNumber(data[col.field]) +  ($filters.toCurrency(col.currency)  ? col.currency : '') }}
                    <i class="mdi mdi-menu-down"></i>
                </span>
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column footer="Gesamt:" :colspan="colspan"/>
                <Column v-for="col of Totals" :key="col.field" :footer="col.total" :footerStyle="col.footerStyle"/>

              </Row>
            </ColumnGroup>
          </DataTable>
        </div>
      </template>
    </Card>
  </div>
</template>

<style lang="scss">
  .mke-dsh-widget.table {
.p-datatable {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .p-card-body {
    overflow-y: hidden;
  }
    .p-card-content {
      padding: 0 !important;
      overflow-y: auto;
    }
  }
</style>

<script>
import {computed, watch, ref, onUpdated} from "vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'
import xlsx from "json-as-xlsx"

export default {
  name: 'WidgetTable',
  props: ["id", "widget_id", "title", "subtitle", "columns", "aggregation"],
  setup(props) {
    const store = useStore()
    const data = computed(() => store.getters["dashboard/get_widget_info"](props.id))
    const widget = computed(() => store.getters["dashboard/get_widget"](props.id))
    const Totals = ref([])
    const colspan = ref(1)
    const { t } = useI18n({ useScope: 'global' })
    const exportToCsv = ref(false)
    const fileName = ref('')
    watch(data, () => {
      if (data.value.value_type != undefined && data.value.value_type === 'table') {
        Totals.value = []
        calculateTotal(data.value.value)
      }
    })
    function calculateTotal(value) {
      let this_columns = JSON.parse(JSON.stringify(value.columns)).filter(column => column.type === "aggregation")
      colspan.value = JSON.parse(JSON.stringify(value.columns)).filter(column => column.type === "group").length
      let columnCurrency = ""
      this_columns.forEach((column) => {
        let total = 0
        if (column.field.endsWith("_percent")){
          total = Number(Totals.value.slice(-2)[0].total.replace(columnCurrency,""))/(Number(Totals.value.slice(-1)[0].total.replace(columnCurrency,""))/100)-100
        }
        else{
          for(let agg of value.data) {
            total += agg[column.field];
            columnCurrency = column.currency
          }
        }
        Totals.value.push({'field':column.field, 'total':total.toFixed(2)+ column.currency, 'footerStyle': column.bodyStyle})
      })
    }
    const exportDataDialogVisible = computed({
      get: () => {return store.state.dashboard.export_data_dialog_visible},
      set: (value) => (store.commit("dashboard/set_export_data_dialog_visible", value))
    })
    const data_table = ref();
    const updatedFileName = ref(false)
    onUpdated(() => {
      if (updatedFileName.value){
        /**
         * because the name of the csv file is passed via a property of the data table
         * (and unfortunately not when calling exportCSV),
         * we need to give Vue a chance to update the property before we export the csv,
         * so the export to the onUpdated Event swapped out because the file name has now been updated.
         */
        data_table.value.exportCSV();
        updatedFileName.value = false
      }
    })
    const onfileNameExport = (fName) => {
      /**
       * Sheet names cannot exceed 31 chars
       */
      fileName.value = fName.name
      if (exportToCsv.value) {
        updatedFileName.value = true
      } else {
        const converted = [{
          sheet: fileName.value.substring(0, 31),
          columns: data.value.value.columns.map(function (obj) {
            return {label: obj.header, value: obj.field}
          }),
          content: data.value.value.data
        }]
        let settings = {
          fileName: fileName.value, // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        }
        xlsx(converted, settings)
      }
    };
    const exportCSV = () => {
      exportDataDialogVisible.value = true
      exportToCsv.value = true
    };
    const exportXLS = () => {
      exportDataDialogVisible.value = true
      exportToCsv.value = false

    };
    return { data, widget, Totals, colspan, t, exportCSV, exportXLS, data_table,  exportDataDialogVisible, fileName, onfileNameExport};
  },
};
</script>
