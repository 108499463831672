import i18n from '../i18n/index.js'
const adminDemo = {
    "countries": {
        "overview": [
            {
                "field": "name",
                "label": i18n.global.t('message.admin.name')
            },
            {
                "field": "code",
                "label": i18n.global.t('message.admin.code')
            }
        ],
        "detail": [
            {
                "id": "country-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.name',
                "field": "name"
            },
            {
                "id": "country-detail-code-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.code',
                "field": "code"
            },
        ]
    },
    "currencies": {
        "overview": [
            {
                "field": "name",
                "label": i18n.global.t('message.admin.field.name.label')
            },
            {
                "field": "iso_code",
                "label": i18n.global.t('message.admin.field.iso_code.label')
            },
            {
                "field": "symbol",
                "label": i18n.global.t('message.admin.field.symbol.label')
            },
        ],
        "detail": [
            {
                "id": "currency-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "field": "name",
                "label": "message.admin.field.name.label",
                "translate": true,
                "translationModel": "currency"
            },
            {
                "id": "currency-detail-iso_code-input-text",
                "component": "mkeFormBuilderInputText",
                "field": "iso_code",
                "label": "message.admin.field.iso_code.label",
                "placeholder": "EUR",
            },
            {
                "id": "currency-detail-conversion_rate-input-number",
                "component": "mkeFormBuilderInputNumber",
                "field": "conversion_rate",
                "label": "message.admin.field.conversion_rate.label",
                "min-fraction-digits": 1,
                "max-fraction-digits": 12,
                "default": 1.0
            },
            {
                "id": "currency-detail-tab",
                "component": "mkeFormBuilderTab",
                "tabs": [
                    {
                        "id": "user-detail-tab-display",
                        "title": "message.admin.display",
                        "elements": [
                            {
                                "id": "currency-detail-decimal_places-input-number",
                                "component": "mkeFormBuilderInputNumber",
                                "field": "decimal_places",
                                "label": "message.admin.field.decimal_places.label",
                                "min-fraction-digits": 0,
                                "max-fraction-digits": 0,
                                "default": 2
                            },
                            {
                                "id": "currency-detail-symbol-input-text",
                                "component": "mkeFormBuilderInputText",
                                "field": "symbol",
                                "label": "message.admin.field.symbol.label",
                            },
                            {
                                "id": "currency-detail-position",
                                "component": "mkeFormBuilderDropdown",
                                "field": "position",
                                "label": "message.admin.field.position.label",
                                "default": "After Amount",
                                "options": [
                                    {"id": "After Amount", "name": i18n.global.t("message.admin.field.position.after_amount")},
                                    {"id": "Before Amount", "name": i18n.global.t("message.admin.field.position.before_amount")}
                                ]
                            },
                        ]
                    },
                    {
                        "id": "user-detail-tab-pos",
                        "title": "message.admin.pos",
                        "elements": [
                            {
                                "id": "currency-detail-pos_allow_cash-input-checkbox",
                                "component": "mkeFormBuilderInputCheckbox",
                                "label": 'message.admin.field.pos_accept_cash.label',
                                "field": "pos_accept_cash",
                            },
                            {
                                "id": "currency-detail-pos_allow_change-input-checkbox",
                                "component": "mkeFormBuilderInputCheckbox",
                                "label": 'message.admin.field.pos_allow_change.label',
                                "field": "pos_allow_change",
                            },
                        ]
                    }
                ]
            }
        ]
    },
    "customers": {
        "overview": [
            {
                "field": "full_name",
                "label": i18n.global.t('message.admin.name')
            },
            {
                "field": "phone",
                "label": i18n.global.t('message.admin.phone')
            },
            {
                "field": "email",
                "label": i18n.global.t('message.admin.email')
            },
            {
                "field": "street",
                "label": i18n.global.t('message.admin.street')
            },
            {
                "field": "city",
                "label": i18n.global.t('message.admin.city')
            },
            {
                "field": "country_name",
                "label": i18n.global.t('message.admin.country_id')
            }
        ],
        "detail": [
            {
                "id": "customer-detail-image",
                "component": "mkeFormBuilderImage",
                "field": "image"
            },
            {
                "id": "customer-detail-tab",
                "component": "mkeFormBuilderTab",
                "tabs": [
                    {
                        "id": "customer-detail-tab-general",
                        "title": "message.admin.general",
                        "elements": [
                            {
                                "id": "customer-detail-is-company-input-checkbox",
                                "component": "mkeFormBuilderInputCheckbox",
                                "label": 'message.admin.is_company',
                                "field": "is_company",
                                "default": true
                            },
                            {
                                "id": "customer-detail-first-parent_id-input-text",
                                "component": "mkeFormBuilderDropdown",
                                "label": 'message.admin.parent_id',
                                "field": "parent_id",
                                "model": "customers",
                                "type": "many2one"
                            },
                            {
                                "id": "customer-detail-name-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.name',
                                "field": "name",
                                "visible_condition": "is_company,true"
                            },
                            {
                                "id": "customer-detail-first-name-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.first_name',
                                "field": "first_name",
                                "visible_condition": "is_company,false"
                            },
                            {
                                "id": "customer-detail-last-name-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.last_name',
                                "field": "last_name",
                                "visible_condition": "is_company,false"
                            },
                            {
                                "id": "customer-detail-street-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.street',
                                "field": "street"
                            },
                            {
                                "id": "customer-detail-street2-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.street2',
                                "field": "street2"
                            },
                            {
                                "id": "customer-detail-zip-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.zip',
                                "field": "zip"
                            },
                            {
                                "id": "customer-detail-city-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.city',
                                "field": "city"
                            },
                            {
                                "id": "customer-detail-first-country_id-input-text",
                                "component": "mkeFormBuilderDropdown",
                                "label": 'message.admin.country_id',
                                "field": "country_id",
                                "model": "countries",
                                "type": "many2one",
                            },
                            {
                                "id": "customer-detail-phone-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.phone',
                                "field": "phone"
                            },
                            {
                                "id": "customer-detail-mobile-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.mobile',
                                "field": "mobile"
                            },
                            {
                                "id": "customer-detail-email-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.email',
                                "field": "email"
                            },
                            {
                                "id": "customer-detail-website-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.website',
                                "field": "website"
                            },
                        ]
                    },
                    {
                        "id": "user-detail-tab-internalNotes",
                        "title": "message.admin.internal_notes",
                        "elements": [
                            {
                                "id": "customer-detail-internalNotes-input-text",
                                "component": "mkeFormBuilderInputTextarea",
                                "label": "message.admin.internal_notes",
                                "field": "internal_notes",
                            },
                        ]
                    }
                ]
            }
        ]
    },
    "devices": {
        "overview": [
            {
                "field": "id",
                "label": i18n.global.t('message.admin.id'),
            },
            {
                "field": "name",
                "label": i18n.global.t('message.admin.name')
            },
            {
                "field": "device_type_name",
                "label": 'message.admin.device.device_type'
            }
        ],
        "detail": [
            {
                "id": "devices-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": i18n.global.t('message.admin.name'),
                "field": "name",
            },
            {
                "id": "devices-detail-microservice-id",
                "component": "mkeFormBuilderDropdown",
                "label": "Microservice",
                "field": "microservice_id",
                "type": "many2one",
                "model": "microservices"
            },
            {
                "id": "devices-detail-device-type-id",
                "component": "mkeFormBuilderDropdown",
                "label": 'message.admin.device.device_type',
                "field": "device_type_id",
                "type": "many2one",
                "model": "device_types"
            }
        ]
    },
    "pos_terminals": {
        "overview": [
            {
                "field": "id",
                "label": i18n.global.t('message.admin.id'),
            },
            {
                "field": "name",
                "label": i18n.global.t('message.admin.name')
            },
        ],
        "detail": [
            {
                "id": "pos-terminals-detail-name",
                "component": "mkeFormBuilderInputText",
                "label": i18n.global.t('message.admin.name'),
                "field": "name",
            },
        ]
    },
    "terminal_settings": {
        "detail": [
            {
                "id": "terminal-settings-detail-authentication-device-id",
                "component": "mkeFormBuilderDropdown",
                "label": "Authentication Device",
                "field": "authentication_device_id",
                "type": "many2one",
                "model": "devices"
            },
        ]
    },
    "authentication_media": {
        "overview": [
            {
                "field": "id",
                "label": i18n.global.t('message.admin.id'),
            },
            {
                "field": "name",
                "label": i18n.global.t('message.admin.name')
            }
        ],
        "detail": [
            {
                "id": "authentication-media-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": i18n.global.t('message.admin.name'),
                "field": "name",
            },
            {
                "id": "authentication-media-detail-user-id",
                "component": "mkeFormBuilderDropdown",
                "label": i18n.global.t('message.admin.user'),
                "field": "user_id",
                "type": "many2one",
                "model": "users"
            }
        ]
    },
    "languages": {
        "overview": [
            {
                "field": "name",
                "label": 'message.admin.name',
            },
            {
                "field": "code",
                "label": "message.admin.code"
            }
        ],
        "detail": [
            {
                "id": "modules-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.name',
                "field": "name",
                "class": ""
            },
            {
                "id": "modules-detail-code-input-text",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.code",
                "field": "code",
                "class": ""
            },
            {
                "id": "modules-detail-iso-code-input-text",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.isoCode",
                "field": "iso_code",
                "class": ""
            },
            {
                "id": "modules-detail-decimal_point-input-text",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.decimalPoint",
                "field": "decimal_point",
                "class": ""
            },
            {
                "id": "modules-detail-thousands_separator-input-text",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.thousandsSeparator",
                "field": "thousands_separator",
                "class": ""
            },
            {
                "id": "modules-detail-date_format-input-text",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.dateFormat",
                "field": "date_format",
                "class": ""
            },
            {
                "id": "modules-detail-time_format-input-text",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.timeFormat",
                "field": "time_format",
                "class": ""
            }
        ]
    },
    "microservices": {
        "overview": [
            {
                "field": "name",
                "label": "message.admin.name",
            },
            {
                "field": "type",
                "label": "message.admin.type",
            },
            {
                "field": "start_date",
                "label": "message.admin.start_date",
                "type": "datetime"
            },
            {
                "field": "update_date",
                "label": "message.admin.update_date",
                "type": "datetime"
            },
            {
                "field": "state",
                "label": "message.admin.state",
                "type": "state"
            }
        ],
        "detail": [
            {
                "id": "microservices-detail-id-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.id',
                "field": "id",
                "readonly": true
            },
            {
                "id": "microservices-detail-start_date-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.start_date',
                "field": "start_date",
                "readonly": true
            },
            {
                "id": "microservices-detail-update_date-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.update_date',
                "field": "update_date",
                "readonly": true
            },
            {
                "id": "microservices-detail-type-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.type',
                "field": "type",
                "readonly": true
            },
            {
                "id": "microservices-detail-state-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.state',
                "field": "state",
                "readonly": true
            }
        ]
    },
    "modules": {
        "overview": [
            {
                "field": "name",
                "label": 'message.admin.name',
            },
            {
                "field": "active",
                "label": "Active"
            }
        ],
        "detail": [
            {
                "id": "modules-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.name',
                "field": "name",
                "class": "",
                "readonly": true
            },
            {
                "id": "modules-detail-active-input-switch",
                "component": "mkeFormBuilderInputSwitch",
                "label": "Active",
                "field": "active",
                "class": ""
            }
        ]
    },
    "privileges": {
    "overview": [
        {
            "field": "name",
            "label": 'message.admin.name'
        },
        {
            "field": "source",
            "label": 'message.admin.source'
        }
    ],
        "detail": [
        {
            "id": 1,
            "component": "mkeFormBuilderInputText",
            "label": 'message.admin.name',
            "field": "name"
        },
        {
            "id": 1,
            "component": "mkeFormBuilderInputText",
            "label": 'message.admin.internalName',
            "field": "internal_name"
        },
        {
            "id": 2,
            "component": "mkeFormBuilderInputText",
            "label": 'message.admin.source',
            "field": "source"
        },
        {
            "id": 3,
            "component": "mkeDataTable",
            "field": "users",
            "model": "users",
            "type": "many2many",
            "selectionMode": "toggle",
            "selectionField": "users",
            "title":  'message.home.users',
            "columns": [
                {
                    "field": "name",
                    "label":  'message.admin.name'
                },
                {
                    "field": "login",
                    "label":  'message.admin.loginName'
                }
            ]
        }
    ]
},
    "users": {
    "overview": [
        {
            "field": "name",
            "label": 'message.admin.name',
        },
        {
            "field": "login",
            "label": 'message.admin.loginName'
        }
    ],
        "detail": [
            {
                "id": "user-detail-tab",
                "component": "mkeFormBuilderTab",
                "tabs": [
                    {
                        "id": "user-detail-tab-general",
                        "title": "message.admin.general",
                        "elements": [
                            {
                                "id": "users-detail-name-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.name',
                                "field": "name"
                            },
                            {
                                "id": "users-detail-login-input-text",
                                "component": "mkeFormBuilderInputText",
                                "label": 'message.admin.loginName',
                                "field": "login"
                            },
                            {
                                "id": "users-detail-language-id",
                                "component": "mkeFormBuilderDropdown",
                                "label": "message.admin.language",
                                "field": "language_id",
                                "type": "many2one",
                                "model": "languages"
                            },
                            {
                                "id": "users-detail-default_app-input-text",
                                "component": "mkeFormBuilderDropdown",
                                "label": 'message.admin.default_app',
                                "field": "default_app",
                                "default": "Home",
                                "options": [
                                    {"id": "Home", "name": "Home"},
                                    {"id": "POS", "name": "Pos"}
                                ]
                            },
                            {
                                "id": "users-detail-default_dashboard_id",
                                "component": "mkeFormBuilderDropdown",
                                "label": 'message.admin.default_dashboard_id',
                                "field": "default_dashboard_id",
                                "type": "many2one",
                                "model": "dashboards",
                                "store": "dashboard"
                            }
                        ]
                    },
                    {
                        "id": "user-detail-tab-security",
                        "title": "message.admin.security",
                        "elements": [
                            {
                                "id": "users-detail-authentication-media-table",
                                "component": "mkeFormBuilderMultiSelect",
                                "field": "authentication_medium_ids",
                                "model": "authentication_media",
                                "type": "one2many",
                                "displayType": "chip",
                                "label": 'message.admin.authenticationMedia'
                            },
                            {
                                "id": "users-detail-privileges-table",
                                "component": "mkeDataTable",
                                "field": "privileges",
                                "model": "privileges",
                                "rows": 10,
                                "rowsPerPageOptions": [10, 20, 50],
                                "type": "many2many",
                                "selectionMode": "toggle",
                                "selectionField": "privileges",
                                "title": 'message.admin.privileges',
                                "columns": [
                                    {
                                        "field": "name",
                                        "label": 'message.admin.name',
                                    },
                                    {
                                        "field": "source",
                                        "label": 'message.admin.source',
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "user_settings": {
        "detail": [
            {
                "id": "users-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.name',
                "field": "name",
            },
            {
                "id": "users-detail-language-id",
                "component": "mkeFormBuilderDropdown",
                "label": "message.admin.language",
                "field": "language_id",
                "type": "many2one",
                "model": "languages",

            }
        ]
    },
    "dsh_user_settings": {
        "detail": [
            {
                "id": "users-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.name',
                "field": "name",
            },
            {
                "id": "users-detail-language-id",
                "component": "mkeFormBuilderDropdown",
                "label": "message.admin.language",
                "field": "language_id",
                "type": "many2one",
                "model": "languages",
            },
            {
                "id": "users-detail-default_dashboard_id",
                "component": "mkeFormBuilderDropdown",
                "label": 'message.admin.default_dashboard_id',
                "field": "default_dashboard_id",
                "type": "many2one",
                "model": "dashboards",
                "store": "dashboard"
            }
        ]
    },
    "products": {
        "overview": [
            {
                "field": "name",
                "label": "message.masterdata.fields.name.label",
                "filter": "contains"
            },
            {
                "field": "button_text",
                "label": "message.masterdata.fields.button_text.label",
                "filter": "contains"
            },
            {
                "field": "plu",
                "label": "message.masterdata.fields.plu.label",
                "filter": "startsWith"
            },
            {
                "field": "visible_on_touch",
                "label": "message.masterdata.fields.visible_on_touch.label",
                "filter": "startsWith"
            },
            {
                "field": "has_combos",
                "label": "message.masterdata.fields.has_combos.label",
                "filter": "startsWith"
            },
            {
                "field": "product_category_name",
                "label": "message.masterdata.fields.product_category.label",
                "filter": "contains"
            },
            {
                "field": "price",
                "label": "message.masterdata.fields.price.label",
                "filter": "startsWith",
                "type": "currency"
            }
        ],
        "detail": [
            {
                "id": "product-detail-tab",
                "component": "mkeFormBuilderTab",
                "tabs": [
                    {
                        "id": "product-detail-tab-general",
                        "title": "message.admin.general",
                        "elements": [
                            {
                                "id": "product-detail-name",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.name.label",
                                "field": "name",
                            },
                            {
                                "id": "product-detail-plu",
                                "component": "mkeFormBuilderInputNumber",
                                "label": "message.masterdata.fields.plu.label",
                                "field": "plu",
                                "min-fraction-digits": 0,
                                "max-fraction-digits": 0,
                                "use-grouping": false
                            },
                            {
                                "id": "product-detail-product-category",
                                "component": "mkeFormBuilderDropdown",
                                "label": "message.masterdata.fields.product_category.label",
                                "field": "product_category_id",
                                "type": "many2one",
                                "model": "product_categories",
                            },
                            {
                                "id": "product-detail-prio",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.prio.label",
                                "field": "prio",
                            },
                            {
                                "id": "product-detail-visible-on-touch",
                                "component": "mkeFormBuilderInputSwitch",
                                "label": "message.masterdata.fields.visible_on_touch.label",
                                "field": "visible_on_touch",
                            },
                            {
                                "id": "product-detail-color",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.color.label",
                                "field": "color",
                            },
                        ]
                    },
                    {
                        "id": "product-detail-tab-texts",
                        "title": "message.masterdata.title.texts",
                        "elements": [
                            {
                                "id": "product-detail-short-name",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.short_name.label",
                                "field": "short_name",
                            },
                            {
                                "id": "product-detail-button-text",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.button_text.label",
                                "field": "button_text",
                            },
                            {
                                "id": "product-detail-printer-text",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.printer_text.label",
                                "field": "printer_text",
                            },
                            {
                                "id": "product-detail-description",
                                "component": "mkeFormBuilderEditor",
                                "label": "message.masterdata.fields.description.label",
                                "field": "description",
                            },
                        ]
                    },
                    {
                        "id": "product-detail-tab-price",
                        "title": "message.masterdata.title.prices",
                        "elements": [
                            {
                                "id": "product-detail-price",
                                "component": "mkeFormBuilderInputNumber",
                                "label": "message.masterdata.fields.price.label",
                                "field": "price",
                                "mode": "decimal",
                                "min-fraction-digits": 2,
                                "max-fraction-digits": 2,
                            },
                            {
                                "id": "product-detail-inhouse-vat",
                                "component": "mkeFormBuilderDropdown",
                                "label": "message.masterdata.fields.inhouse_vat.label",
                                "field": "inhouse_vat_id",
                                "type": "many2one",
                                "model": "vats",
                            },
                            {
                                "id": "product-detail-outhouse-vat",
                                "component": "mkeFormBuilderDropdown",
                                "label": "message.masterdata.fields.outhouse_vat.label",
                                "field": "outhouse_vat_id",
                                "type": "many2one",
                                "model": "vats",
                            },
                        ]
                    },
                    {
                        "id": "product-detail-tab-combo",
                        "title": "message.masterdata.fields.combo.title",
                        "elements": [
                            {
                                "id": "product-detail-combo",
                                "component": "PosFormBuilderComboEdit",
                                "label": "message.masterdata.fields.combo.label",
                                "type": "tree",
                                "field": "product_combo",
                                "level": 1,
                                "class": "p-col-12 "
                            },
                        ]
                    },

                ]
            }
        ]
    },
    "product_properties": {
        "overview": [
            {
                "field": "name",
                "label": "message.admin.name",
            },
            {
                "field": "short_name",
                "label": "message.admin.short_name",
            }
        ],
        "detail": [
            {
                "id": "product-property-detail-image",
                "component": "mkeFormBuilderImage",
                "field": "image"
            },
            {
                "id": "product-property-detail-product-property-category",
                "component": "mkeFormBuilderDropdown",
                "label": "message.admin.category",
                "field": "product_property_category_id",
                "type": "many2one",
                "model": "product_property_categories",
            },
            {
                "id": "product-property-detail-name",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.name",
                "field": "name",
                "translate": true,
                "translationModel": "product_property"
            },
            {
                "id": "product-property-detail-short_name",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.short_name",
                "field": "short_name"
            },
            {
                "id": "product-property-detail-description",
                "component": "mkeFormBuilderInputTextarea",
                "label": "message.admin.description",
                "field": "description",
                "translate": true,
                "translationModel": "product_property"
            }
        ]
    },
    "product_property_categories": {
        "overview": [
            {
                "field": "name",
                "label": "message.admin.name",
            }
        ],
        "detail": [
            {
                "id": "product-property-category-detail-name",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.name",
                "field": "name",
                "translate": true,
                "translationModel": "product_property_category"
            },
            {
                "id": "product-property-category-detail-description",
                "component": "mkeFormBuilderInputTextarea",
                "label": "message.admin.description",
                "field": "description",
                "translate": true,
                "translationModel": "product_property_category"
            }
        ]
    },
    "product_categories": {
        "overview": [
            {
                "field": "name",
                "label": "message.masterdata.fields.name.label",
                "filter": "contains"
            },
            {
                "field": "parent_name",
                "label": "message.masterdata.fields.parent_name.label",
                "filter": "contains"
            }
        ],
        "detail": [
            {
                "id": "product-category-detail-tab",
                "component": "mkeFormBuilderTab",
                "class": "p-col-12 mke-pl-0",
                "tabs": [
                    {
                        "id": "product-category-detail-tab-general",
                        "title": "message.admin.general",
                        "elements": [
                            {
                                "id": "product-category-detail-name",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.name.label",
                                "field": "name",
                            },
                            {
                                "id": "product-category-detail-parent-id",
                                "component": "mkeFormBuilderDropdown",
                                "label": "message.masterdata.fields.parent.label",
                                "field": "parent_id",
                                "type": "many2one",
                                "model": "product_categories",
                            },
                            {
                                "id": "product-category-detail-inhouse-vat",
                                "component": "mkeFormBuilderDropdown",
                                "label": "message.masterdata.fields.inhouse_vat.label",
                                "field": "inhouse_vat_id",
                                "type": "many2one",
                                "model": "vats",
                            },
                            {
                                "id": "product-category-detail-outhouse-vat",
                                "component": "mkeFormBuilderDropdown",
                                "label": "message.masterdata.fields.outhouse_vat.label",
                                "field": "outhouse_vat_id",
                                "type": "many2one",
                                "model": "vats",
                            },
                            {
                                "id": "product-category-detail-plu",
                                "component": "mkeFormBuilderInputNumber",
                                "label": "message.masterdata.fields.plu.label",
                                "field": "plu",
                                "min-fraction-digits": 0,
                                "max-fraction-digits": 0,
                                "use-grouping": false
                            },
                            {
                                "id": "product-category-detail-color",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.color.label",
                                "field": "color",
                            },
                            {
                                "id": "product-category-detail-prio",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.prio.label",
                                "field": "prio",
                            },
                            {
                                "id": "product-category-detail-visible-on-touch",
                                "component": "mkeFormBuilderInputSwitch",
                                "label": "message.masterdata.fields.visible_on_touch.label",
                                "field": "visible_on_touch",
                            },
                        ]
                    },
                    {
                        "id": "product-category-detail-tab-texts",
                        "title": "message.masterdata.title.texts",
                        "elements": [
                            {
                                "id": "product-category-detail-short-name",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.short_name.label",
                                "field": "short_name",
                            },
                            {
                                "id": "product-category-detail-button-text",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.button_text.label",
                                "field": "button_text",
                            },
                            {
                                "id": "product-category-detail-printer-text",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.printer_text.label",
                                "field": "printer_text",
                            },
                            {
                                "id": "product-category-detail-description",
                                "component": "mkeFormBuilderEditor",
                                "label": "message.masterdata.fields.description.label",
                                "field": "description",
                            },
                        ]
                    },
                ]
            }
        ]
    },
    "translations": {
        "overview": [
            {
                "field": "model_name",
                "label": 'message.admin.model_name',
            },
            {
                "field": "field_name",
                "label": 'message.admin.field_name',
            },
            {
                "field": "name",
                "label": 'message.admin.name',
            },
            {
                "field": "language_code",
                "label": 'message.admin.language_code',
            }
        ],
        "detail": [
            {
                "id": "translations-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.name',
                "field": "name"
            },
            {
                "id": "translations-detail-object_id-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.object_id',
                "field": "object_id"
            },
            {
                "id": "translations-detail-model_name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.model_name',
                "field": "model_name"
            },
            {
                "id": "translations-detail-field_name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.admin.field_name',
                "field": "field_name"
            },
            {
                "id": "modules-detail-language_code-input-text",
                "component": "mkeFormBuilderInputText",
                "label": "message.admin.language_code",
                "field": "language_code"
            }
        ]
    },
    "vats": {
        "overview": [
            {
                "field": "name",
                "label": "message.masterdata.fields.name.label"
            },
            {
                "field": "code",
                "label": "message.masterdata.fields.code.label"
            },
            {
                "field": "rate",
                "label": "message.masterdata.fields.rate.label"
            }
        ],
        "detail": [
            {
                "id": "vat-detail-name",
                "component": "mkeFormBuilderInputText",
                "label": "message.masterdata.fields.name.label",
                "field": "name"
            },
            {
                "id": "vat-detail-short-name",
                "component": "mkeFormBuilderInputText",
                "label": "message.masterdata.fields.short_name.label",
                "field": "short_name"
            },
            {
                "id": "vat-detail-code",
                "component": "mkeFormBuilderInputText",
                "label": "message.masterdata.fields.code.label",
                "field": "code"
            },
            {
                "id": "vat-detail-rate",
                "component": "mkeFormBuilderInputText",
                "label": "message.masterdata.fields.rate.label",
                "field": "rate"
            },
            {
                "id": "vat-detail-fiscal-tax-sign",
                "component": "mkeFormBuilderInputText",
                "label": "message.masterdata.fields.fiscal_tax_sign.label",
                "field": "fiscal_tax_sign"
            }
        ]
    },
    "product_groups": {
        "overview": [
            {
                "field": "name",
                "label": "message.masterdata.fields.name.label",
                "filter": "contains"
            }
        ],
        "detail": [{
            "id": "product-group-detail-tab",
            "component": "mkeFormBuilderTab",
            "tabs": [
                {
                    "id": "product-group-detail-tab-general",
                    "title": "message.admin.general",
                    "elements": [
                        {
                            "id": "product-group-detail-name",
                            "component": "mkeFormBuilderInputText",
                            "field": "name",
                            "label": "message.masterdata.fields.name.label"
                        },
                        {
                            "id": "product-group-detail-short-name",
                            "component": "mkeFormBuilderInputText",
                            "field": "short_name",
                            "label": "message.masterdata.fields.short_name.label"
                        },
                        {
                            "id": "product-group-detail-button-text",
                            "component": "mkeFormBuilderInputText",
                            "field": "button_text",
                            "label": "message.masterdata.fields.button_text.label"
                        }
                    ]
                },
                {
                    "id": "product-group-detail-tab-products",
                    "title": "message.masterdata.fields.products.label",
                    "elements": [
                        {
                            "id": "product-group-detail-products",
                            "component": "mkeFormBuilderOneToManyPanel",
                            "label": "message.masterdata.fields.products.label",
                            "field": "products_of_group",
                            "class": "p-col-12 ",
                            "elements": [{
                                "component": "mkeFormBuilderDisplayValue",
                                "field": "name"
                            }],
                            "add" : {
                                "component": "mkeFormBuilderAddItemDialog",
                                "title": "message.masterdata.dialogs.add_product",
                                "endpoint": "products",
                                "mapping" : {
                                    "product_id": "id",
                                    "name": "name"
                                }
                            }
                        },
                    ]
                }]
        }]
    },
    "function_groups": {
        "overview": [
            {
                "field": "name",
                "label": "message.masterdata.fields.name.label",
                "filter": "contains"
            }
        ],
        "detail": [{
            "id": "function-group-detail-tab",
            "component": "mkeFormBuilderTab",
            "tabs": [
                {
                    "id": "function-group-detail-tab-general",
                    "title": "message.admin.general",
                    "elements": [
                        {
                            "id": "function-group-detail-name",
                            "component": "mkeFormBuilderInputText",
                            "field": "name",
                            "label": "message.masterdata.fields.name.label"
                        },
                        {
                            "id": "function-group-detail-short-name",
                            "component": "mkeFormBuilderInputText",
                            "field": "short_name",
                            "label": "message.masterdata.fields.short_name.label"
                        },
                        {
                            "id": "function-group-detail-button-text",
                            "component": "mkeFormBuilderInputText",
                            "field": "button_text",
                            "label": "message.masterdata.fields.button_text.label"
                        },
                        {
                            "id": "function-group-detail-icon",
                            "component": "mkeFormBuilderInputText",
                            "field": "icon",
                            "label": "message.masterdata.fields.icon.label"
                        },
                        {
                            "id": "function-group-detail-color",
                            "component": "mkeFormBuilderInputText",
                            "field": "color",
                            "label": "message.masterdata.fields.color.label"
                        },

                        {
                            "id": "function-group-detail-button_layout",
                            "component": "mkeFormBuilderDropdown",
                            "field": "button_layout",
                            "label": "message.masterdata.fields.button_layout.label",
                            "options": [
                                {"id": "floating", "name": "Fliessend"},
                                {"id": "grid", "name": "Grid"}
                            ]
                        },
                        {
                            "id": "function-group-detail-grid_cols",
                            "component": "mkeFormBuilderInputNumber",
                            "label": "message.masterdata.fields.grid_cols.label",
                            "field": "grid_cols",
                            "min-fraction-digits": 0,
                            "max-fraction-digits": 0,
                            "use-grouping": false
                        },
                        {
                            "id": "function-group-detail-grid_rows",
                            "component": "mkeFormBuilderInputNumber",
                            "label": "message.masterdata.fields.grid_rows.label",
                            "field": "grid_rows",
                            "min-fraction-digits": 0,
                            "max-fraction-digits": 0,
                            "use-grouping": false
                        },
                    ]
                },
                {
                    "id": "function-group-detail-tab-functions",
                    "title": "message.masterdata.fields.functions.label",
                    "elements": [
                        {
                            "id": "function-group-detail-functions",
                            "component": "mkeFormBuilderOneToManyPanel",
                            "label": "message.masterdata.fields.functions.label",
                            "field": "functions_of_group",
                            "class": "p-col-12 ",
                            "elements": [{
                                "component": "mkeFormBuilderDisplayValue",
                                "field": "name"
                            },],
                            "add" : {
                                "component": "PosFormBuilderSlotMachineDialog",
                                "title": "message.masterdata.dialogs.add_function",
                                "mode": "add",
                                "mapping" : {
                                    "name": "name", "short_name": "short_name", "button_text": "button_text",
                                    "icon": "icon", "color": "color", "fontsize": "fontsize",
                                    "click_action_name": "click_action_name", "click_action_param": "click_action_param",
                                    "long_pressed_action_name": "long_pressed_action_name", "long_pressed_action_param": "long_pressed_action_param",
                                    "grid_x": "grid_x", "grid_y": "grid_y", "grid_width": "grid_width", "grid_height": "grid_height"
                                }
                            },
                            "edit" : {
                                "component": "PosFormBuilderSlotMachineDialog",
                                "title": "message.masterdata.dialogs.edit_function",
                                "mode": "edit",
                                "mapping" : {
                                    "name": "name", "short_name": "short_name", "button_text": "button_text",
                                    "icon": "icon", "color": "color", "fontsize": "fontsize",
                                    "click_action_name": "click_action_name", "click_action_param": "click_action_param",
                                    "long_pressed_action_name": "long_pressed_action_name", "long_pressed_action_param": "long_pressed_action_param",
                                    "grid_x": "grid_x", "grid_y": "grid_y", "grid_width": "grid_width", "grid_height": "grid_height"
                                }
                            }
                        },
                    ]
                },
                {
                    "id": "function-group-detail-tab-preview",
                    "title": "message.masterdata.button.preview",
                    "elements": [
                        {
                            "id": "function-group-detail-preview",
                            "component": "PosActionPanel",
                            "label": "message.masterdata.button.preview",
                            "field": "functions_of_group",
                            "mode": "editor",
                            "class": "p-col-12 "
                        },
                    ]
                }
            ]
        }]
    },
    "pos_navigations": {
        "overview": [
            {
                "field": "name",
                "label": "message.masterdata.fields.name.label",
                "filter": "contains"
            },

            {
                "field": "navigation_target",
                "label": "message.masterdata.fields.navigation_target.label",
                "translation_prefix": "message.masterdata.fields.navigation_target.option.",
                "options": [
                        {"id": "child", "name": "Kind", "label": "message.masterdata.fields.navigation_target.option.child"},
                        {"id": "product_explorer", "name": "Product-Explorer", "label": "message.masterdata.fields.navigation_target.option.product_explorer"},
                        {"id": "main_function", "name": "Main Functions", "label": "message.masterdata.fields.navigation_target.option.main_function"},
                        {"id": "function_a", "name": "Functions A", "label": "message.masterdata.fields.navigation_target.option.function_a"},
                        {"id": "function_b", "name": "Functions b", "label": "message.masterdata.fields.navigation_target.option.function_b"},
                        {"id": "order", "name": "Order Functions", "label": "message.masterdata.fields.navigation_target.option.order"},
                        {"id": "process", "name": "Process Functions", "label": "message.masterdata.fields.navigation_target.option.process"},
                        {"id": "numpad", "name": "Numpad", "label": "message.masterdata.fields.navigation_target.option.numpad"},
                ]
            },
            {
                "field": "navigation_type",
                "label": "message.masterdata.fields.navigation_type.label",
                "translation_prefix": "message.masterdata.fields.navigation_type.option.",
                "options": [
                    {"id": "category", "name": "Produkt-Kategorie", "label": "message.masterdata.fields.navigation_type.option.category"},
                    {"id": "product_group", "name": "Produktgruppe", "label": "message.masterdata.fields.navigation_type.option.product_group"},
                    {"id": "function", "name": "Funktionsgruppe", "label": "message.masterdata.fields.navigation_type.option.function"},
                    {"id": "collection", "name": "Zusammenstellung", "label": "message.masterdata.fields.navigation_type.option.collection"}
                ]
            },
            {
                "field": "content_name",
                "label": "message.masterdata.fields.content_name.label",
                "filter": "contains"
            },
            {
                "field": "parent_id",
                "label": "message.masterdata.fields.parent.label",
                "filter": "contains"
            }
        ],
        "detail": [{
            "id": "pos_navigations-detail-tab",
            "component": "mkeFormBuilderTab",
            "tabs": [
                {
                    "id": "pos_navigations-detail-tab-general",
                    "title": "message.admin.general",
                    "elements": [
                        {
                            "id": "pos_navigations-detail-parent-id",
                            "component": "mkeFormBuilderDropdown",
                            "label": "message.masterdata.fields.parent.label",
                            "field": "parent_id",
                            "type": "many2one",
                            "model": "pos_navigations",
                            "filter" : {
                                "navigation_type": "collection"
                            }
                        },
                        {
                            "id": "pos_navigations-detail-index",
                            "component": "mkeFormBuilderInputNumber",
                            "field": "index",
                            "label": "message.masterdata.fields.index.label",
                            "min-fraction-digits": 0,
                            "max-fraction-digits": 0,
                            "use-grouping": false
                        },
                        {
                            "id": "pos_navigations-detail-name",
                            "component": "mkeFormBuilderInputText",
                            "field": "name",
                            "label": "message.masterdata.fields.name.label"
                        },
                        {
                            "id": "pos_navigations-detail-short-name",
                            "component": "mkeFormBuilderInputText",
                            "field": "short_name",
                            "label": "message.masterdata.fields.short_name.label"
                        },
                        {
                            "id": "pos_navigations-detail-button-text",
                            "component": "mkeFormBuilderInputText",
                            "field": "button_text",
                            "label": "message.masterdata.fields.button_text.label"
                        },
                        {
                            "id": "pos_navigations-detail-icon",
                            "component": "mkeFormBuilderInputText",
                            "field": "icon",
                            "label": "message.masterdata.fields.icon.label"
                        },
                        {
                            "id": "pos_navigations-detail-color",
                            "component": "mkeFormBuilderInputText",
                            "field": "color",
                            "label": "message.masterdata.fields.color.label"
                        },
                        {
                            "id": "pos_navigations-detail-navigation_target",
                            "component": "mkeFormBuilderDropdown",
                            "field": "navigation_target",
                            "label": "message.masterdata.fields.navigation_target.label",
                            "options": [
                                {"id": "child", "name": "Kind", "label": "message.masterdata.fields.navigation_target.option.child"},
                                {"id": "product_explorer", "name": "Product-Explorer", "label": "message.masterdata.fields.navigation_target.option.product_explorer"},
                                {"id": "main_function", "name": "Main Functions", "label": "message.masterdata.fields.navigation_target.option.main_function"},
                                {"id": "function_a", "name": "Functions A", "label": "message.masterdata.fields.navigation_target.option.function_a"},
                                {"id": "function_b", "name": "Functions b", "label": "message.masterdata.fields.navigation_target.option.function_b"},
                                {"id": "order", "name": "Order Functions", "label": "message.masterdata.fields.navigation_target.option.order"},
                                {"id": "process", "name": "Process Functions", "label": "message.masterdata.fields.navigation_target.option.process"},
                                {"id": "numpad", "name": "Numpad", "label": "message.masterdata.fields.navigation_target.option.numpad"},
                            ]
                        },
                        {
                            "component": "mkeFormBuilderSelectItem",
                            "title": "message.masterdata.dialogs.select_content",
                            "field": "content_id",
                            "option_key": "content_id",
                            "option_label": "name",
                            "label": "message.masterdata.fields.content_name.label",
                            "endpoint": "pos_navigations/content",
                            "mapping" : {
                                "content_id": "content_id",
                                "content_name": "name",
                                "navigation_type": "navigation_type"
                            }
                        }

                    ]
                },
                {
                    "id": "pos_navigation-detail-tab-sub_navigations",
                    "title": "message.masterdata.title.sub_navigations",
                    "elements": [
                        {
                            "id": "pos_navigation-detail-sub_navigations",
                            "component": "mkeFormBuilderOneToManyPanel",
                            "label": "message.masterdata.fields.sub_navigations.label",
                            "field": "sub_navigations",
                            "class": "p-col-12 ",
                            "elements": [{
                                "component": "mkeFormBuilderDisplayValue",
                                "field": "name"
                            },{
                                "component": "mkeFormBuilderDisplayValue",
                                "field": "content_name"
                            }],
                            "add" : {
                                "component": "mkeFormBuilderAddItemDialog",
                                "title": "message.masterdata.dialogs.add_navigation",
                                "endpoint": "pos_navigations",
                                "mapping" : {
                                    "id": "id",
                                    "name": "name",
                                    "short_name": "short_name",
                                    "button_text": "button_text",
                                    "icon": "icon",
                                    "color": "color",
                                    "navigation_type": "navigation_type",
                                    "navigation_target": "navigation_target",
                                    "content_id": "content_id"
                                }
                            }
                        },
                    ]
                }]
        }]
    },
    "system_settings": {
        "detail": [
            {
                "id": "system-settings-detail-default_currency-id",
                "component": "mkeFormBuilderDropdown",
                "label": "message.admin.field.default_currency.label",
                "field": "default_currency_id",
                "type": "many2one",
                "model": "currencies"
            },
            {
                "id": "system-settings-detail-default_first_day-id",
                "component": "mkeFormBuilderDropdown",
                "label": "message.admin.field.default_first_day.label",
                "field": "first_day_of_week",
                "options": [
                    {"id": "Sunday", "name": i18n.global.t("message.dashboard.calendar.sunday")},
                    {"id": "Monday", "name": i18n.global.t("message.dashboard.calendar.monday")},
                ]
            },
        ]
    },
    "print_layouts": {
        "overview": [
            {
                "field": "name",
                "label": "message.masterdata.fields.name.label"
            },
            {
                "field": "print_layout_type",
                "label": "message.masterdata.fields.print_layout_type.label"
            }
        ],
        "detail": [
            {
                "id": "print-layout-detail-tab",
                "component": "mkeFormBuilderTab",
                "tabs": [
                    {
                        "id": "print-layout-detail-tab-general",
                        "title": "message.admin.general",
                        "elements": [
                            {
                                "id": "print-layout-detail-name",
                                "component": "mkeFormBuilderInputText",
                                "label": "message.masterdata.fields.name.label",
                                "field": "name"
                            },
                            {
                                "id": "print-layout-detail-print-layout-type",
                                "component": "mkeFormBuilderDropdown",
                                "label": "message.masterdata.fields.print_layout_type.label",
                                "field": "print_layout_type",
                                "options": [
                                    {
                                        "id": "bon",
                                        "name": "Bon",
                                        "label": "message.masterdata.fields.print_layout_type.option.bon"
                                    },
                                    {
                                        "id": "bill",
                                        "name": "Rechnung",
                                        "label": "message.masterdata.fields.print_layout_type.option.bill"
                                    },
                                    {
                                        "id": "void",
                                        "name": "Storno",
                                        "label": "message.masterdata.fields.print_layout_type.option.void"
                                    }
                                ]
                            }]
                    }, {
                        "id": "print-layout-detail-tab-editor",
                        "title": "message.print_layout.editor",
                        "elements": [{
                            "id": "print-layout-detail-layout",
                            "component": "prnFormBuilderPrintLayoutEditor",
                            "label": "message.masterdata.fields.print_layout_content.label",
                            "field": "wysiwyg_definition"
                        }]
                    }, {
                        "id": "print-layout-detail-tab-source",
                        "title": "message.print_layout.source",
                        "elements": [{
                            "id": "print-layout-detail-content",
                            "component": "mkeFormBuilderInputTextarea",
                            "readonly": true,
                            "label": "message.masterdata.fields.print_layout_content.label",
                            "field": "content"
                        }]
                    }]
            }

        ]
    },
    "print_rules": {
        "overview": [
            {
                "field": "name",
                "label": "message.masterdata.fields.name.label"
            },
            {
                "field": "active",
                "label": "message.masterdata.fields.active.label"
            },
            {
                "field": "priority",
                "label": "message.masterdata.fields.priority.label"
            },
            {
                "field": "outhouse",
                "label": "message.masterdata.fields.outhouse.label"
            },
            {
                "field": "product_category_name",
                "label": "message.masterdata.fields.product_category.label"
            },
            {
                "field": "user_name",
                "label": "message.masterdata.fields.user.label"
            },
            {
                "field": "printer_name",
                "label": "message.masterdata.fields.printer.label"
            },
            {
                "field": "print_layout_name",
                "label": "message.masterdata.fields.print_layout.label"
            },
            {
                "field": "fallback",
                "label": "message.masterdata.fields.fallback.label"
            },
            {
                "field": "fallback_printer_name",
                "label": "message.masterdata.fields.fallback_printer_name.label"
            },
        ],
        "detail": [
            {
                "id": "print-rule-detail-name",
                "component": "mkeFormBuilderInputText",
                "label": "message.masterdata.fields.name.label",
                "field": "name"
            },
            {
                "id": "print-rule-detail-active",
                "component": "mkeFormBuilderInputCheckbox",
                "label": "message.masterdata.fields.active.label",
                "field": "active"
            },
            {
                "id": "print-rule-detail-priority",
                "component": "mkeFormBuilderInputNumber",
                "label": "message.masterdata.fields.priority.label",
                "field": "priority",
                "min-fraction-digits": 0,
                "max-fraction-digits": 0,
                "use-grouping": false
            },
            {
                "id": "print-rule-detail-outhouse",
                "component": "mkeFormBuilderInputCheckbox",
                "label": "message.masterdata.fields.outhouse.label",
                "field": "outhouse"
            },
            {
                "id": "print-rule-detail-fallback",
                "component": "mkeFormBuilderInputCheckbox",
                "label": "message.masterdata.fields.fallback.label",
                "field": "fallback"
            },
            {
                "id": "print-rule-detail-fallback_printer",
                "component": "mkeFormBuilderDropdown",
                "label": "message.masterdata.fields.fallback_printer_name.label",
                "field": "fallback_printer_id",
                "type": "many2one",
                "model": "devices",
                "filter": { "device_type_name":"printer" }
            },
            {
                "id": "print-rule-detail-product_category",
                "component": "mkeFormBuilderDropdown",
                "label": "message.masterdata.fields.product_category.label",
                "field": "product_category_id",
                "type": "many2one",
                "model": "product_categories",
            },
            {
                "id": "print-rule-detail-product_group",
                "component": "mkeFormBuilderDropdown",
                "label": "message.masterdata.fields.product_group.label",
                "field": "product_group_id",
                "type": "many2one",
                "model": "product_groups",
            },
            {
                "id": "print-rule-detail-user",
                "component": "mkeFormBuilderDropdown",
                "label": "message.masterdata.fields.user.label",
                "field": "user_id",
                "type": "many2one",
                "model": "users",
            },
            {
                "id": "print-rule-detail-printer",
                "component": "mkeFormBuilderDropdown",
                "label": "message.masterdata.fields.printer.label",
                "field": "printer_id",
                "type": "many2one",
                "model": "devices",
                "filter": { "device_type_name":"printer" }
            },
            {
                "id": "print-rule-detail-print_layout",
                "component": "mkeFormBuilderDropdown",
                "label": "message.masterdata.fields.print_layout.label",
                "field": "print_layout_id",
                "type": "many2one",
                "model": "print_layouts",
            },
        ]
    }

}
export default adminDemo

function translateSchemaHelper(data, field){
    data[field] = i18n.global.t(data[field])
    return data
}

/**
 * Translate the given schema
 */
function translateSchema(res){
    res.filter(x => x.label != null).map(x => translateSchemaHelper(x, "label"))
    res.filter(x => x.title != null).map(x => translateSchemaHelper(x, "title"))
    res.filter(x => !x.label && x.title !== null).map(x => x.label = x.title)
    res.filter(x => !x.label && x.name !== null).map(x => x.label = x.name)

    res.filter(x => x.columns != null).map(x => translateSchema(x.columns))
    res.filter(x => x.elements != null).map(x => translateSchema(x.elements))
    res.filter(x => x.tabs != null).map(x => translateSchema(x.tabs))
    res.filter(x => x.options != null).map(x => translateSchema(x.options))

    return res
}

/**
 * Translate and return a schema
 * @param model The model to get the schema for. For example: users
 * @param viewMode The view mode to get the schema for. For example: detail or overview
 * @returns {*}
 */
function getTranslatedSchema (model, viewMode) {
    if( adminDemo[model] && adminDemo[model][viewMode] ) {
        let modelCopy = JSON.parse(JSON.stringify(adminDemo[model][viewMode]))
        return translateSchema(modelCopy)
    } else {
        return {};
    }
}

export { getTranslatedSchema }
