<template>
  <div id="component-split-entries" class="p-d-flex gap-2" :class="{'with-split':splitsActivated}">
    <div class="p-d-flex p-flex-column gap-2" style="flex:1" >
      <div class="p-d-flex p-jc-between">
        <div style="flex:1">
          <div class="mx-fg-text-medium" v-if="splitsDeactivated">Übersicht</div>
          <div class="mx-fg-text-medium" v-else>Quelle/Von</div>
          <h4 class="">{{splitStore.sourceProcess.name}}</h4>
        </div>
        <mke-button label="Split hinzufügen" v-if="mode==='payment' && splitsDeactivated && splitStore.payedPrice() === 0" @click="$emit('split:added')"/>
      </div>

      <Divider layout="horizontal" class="mke-m-0 mke-p-0"/>

      <div class="table-panel" >
        <table class="split-panel">
          <TransitionGroup tag="tbody" name="fade-in-from-left">
            <tr v-for="transactionItem in entriesForSplit"
                :id="'process-entry-' + transactionItem.id"
                :key="transactionItem.id"
                class="product-item"
                :class="transactionItem.cssClass"
                :data-quantity="transactionItem.quantity"
                :data-state="transactionItem.state"
                :data-name="transactionItem.name"
                :data-hint="transactionItem.hint"
                :data-inhouse="transactionItem.inhouse"
                :data-base-price="transactionItem.base_price"
                :data-price="transactionItem.price"
                :data-entry-type="transactionItem.entry_type"
                :data-memo="transactionItem.memo"
                :data-plu="transactionItem.plu">

              <td class="product-count text-align-right mke-p-1">{{renderQuantity(transactionItem.quantity)}}</td>
              <td class="w-100 mke-p-1" >
                <Button class="product-button w-100"
                        :class="[transactionItem.quantity > 0 ? '' : '']"
                        :disabled="splitDisabled && splitsActivated"
                        @click="addProductToSplit(transactionItem, splitStore.currentSplit)">
                  <div class="p-d-flex p-jc-between w-100" style="gap:1rem">
                    <div class="product-name text-align-left">
                      {{ transactionItem.name }}
                      <small>{{entryDetails(transactionItem)}}</small>
                    </div>
                    <div class="product-price">{{ n(transactionItem.price, 'currency') }}</div>
                  </div>
                </Button>
              </td>
            </tr>
          </TransitionGroup>
        </table>
      </div>
      <div v-if="splitStore.payedPrice() > 0"
          class="text-align-right font-size-m font-weight-bold total_price"
          :data-amount="splitStore.sumOfEntries()">
        {{t('message.pos.total_price')}}: {{n(splitStore.sumOfEntries(), 'currency')}}
      </div>
      <div v-if="splitStore.payedPrice() > 0"
          class="text-align-right font-size-m font-weight-bold payed_price"
          :data-amount="splitStore.payedPrice()">
        {{t('message.pos.payment.payed')}}: {{n(splitStore.payedPrice(), 'currency')}}
      </div>
      <div class="text-align-right font-size-xl font-weight-bold mke-p-1 open_price"
          :data-amount="splitStore.openPrice()">
        {{t('message.pos.payment.not_payed')}}: {{n(splitStore.openPrice(), 'currency')}}
      </div>
      <div class="p-d-flex gap-2 p-jc-between" >
        <mke-button v-if="noOfSplit > 0" @click="distributeAll"
                    class="p-button-secondary" label="Gesamt verteilen" />
        <mke-button v-if="isSplitEnabled(splitStore.currentSplit)"
                    id="button-pos-process-take-over" label="Rest übernehmen" color="secondary"
                    icon="mdi mdi-arrow-right-bold" iconPos="right"
                    @click="takeover(splitStore.currentSplit)" ></mke-button>
      </div>
    </div>

    <div class="p-d-flex p-flex-column gap-2" style="flex:1" v-if="splitsActivated">
      <div class="mx-fg-text-medium">Ziel/nach</div>
      <TabView id="tab-of-splits"
               class="pos-split-entries "
               v-model:activeIndex="activeTabIndex"
               @tab-change="tabChanged">

        <TabPanel v-for="split in noOfSplit" :key="split" >
          <template #header>
            <i class="mdi mdi-check mke-mr-1" v-if="isSplitDisabled(split)"></i>
            <span :data-split="split">{{splitStore.nameOfSplit(split)}}</span>
          </template>
            <div class="p-d-flex p-flex-column p-jc-between gap-2 h-100" style="flex:1" :data-split="split">
              <div class="table-panel">
                <table class="split-panel">
                  <TransitionGroup tag="tbody" name="fade-in-from-left">
                    <tr
                        v-for="transactionItem in splitStore.splittedEntriesOfSplit(split)"
                        :id="'process-entry-' + transactionItem.id + '-' + split"
                        :key="'process-entry-' + transactionItem.id + '-' + split"
                        class="product-item"
                        :class="transactionItem.cssClass"
                        :data-quantity="transactionItem.quantity"
                        :data-state="transactionItem.state"
                        :data-name="transactionItem.name"
                        :data-hint="transactionItem.hint"
                        :data-inhouse="transactionItem.inhouse"
                        :data-base-price="transactionItem.base_price"
                        :data-price="transactionItem.price"
                        :data-entry-type="transactionItem.entry_type"
                        :data-memo="transactionItem.memo"
                        :data-plu="transactionItem.plu">

                      <td class="product-count text-align-right mke-p-1">{{renderQuantity(transactionItem.splittedEntries[split-1].quantity)}}</td>
                      <td class="w-100 mke-p-1">
                        <Button class="product-button p-button-primary w-100"
                                :disabled="isSplitDisabled(split)"
                                @click="removeProductFromSplit(transactionItem, splitStore.currentSplit)">
                          <div class="p-d-flex p-jc-between w-100" style="gap:1rem">
                            <div class="product-name text-align-left">
                              {{ transactionItem.name }} &nbsp;
                              <small>{{entryDetails(transactionItem)}}</small>
                            </div>
                            <div class="product-price">{{ n(transactionItem.splittedEntries[split-1].price, 'currency') }}</div>
                          </div>
                        </Button>
                      </td>
                    </tr>
                  </TransitionGroup>
                </table>
              </div>
              <div class="text-align-right font-size-xl font-weight-bold splitted_price"
                  :data-amount="splitStore.sumOfSplittedEntries(split)">
                Gesamt: {{n(splitStore.sumOfSplittedEntries(split), 'currency')}}
              </div>
              <div class="p-d-flex p-jc-between gap-2" v-if="isSplitEnabled(split)">
                <mke-button :id="'button-pos-process-reset-split' + split" label="Split zurücksetzen" color="secondary" icon="mdi mdi-arrow-left-bold"
                          @click="resetSplit(split)" ></mke-button>
                <mke-button :id="'button-pos-process-remove-split' + split" label="Split löschen" color="danger" icon="mdi mdi-trash-can"
                          @click="removeSplit(split)" ></mke-button>
              </div>
            </div>
        </TabPanel>
        <TabPanel header="+ neuer Split" v-if="mode==='payment'">
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {useSplitEntriesStore} from "@/store/pinia/pos/SplitEntriesStore";
import {storeToRefs} from "pinia";
import {computed, ref} from "vue";

export default {
  name: "PosProcessSplitEntries",
  props: ["id", "numpadState", "mode"],
  emits: ['split:selected', 'split:removed', 'split:added', 'numpad:consumed'],
  setup(props, ctx) {
    const {t, n} = useI18n({useScope: 'global'});
    const splitStore = useSplitEntriesStore();

    const {
      splitEntries,
      entriesForSplit,
      noOfSplit,
      currentSplit
    } = storeToRefs(splitStore);

    const splitsActivated = computed(()=> noOfSplit.value > 0);
    const splitsDeactivated = computed(()=> noOfSplit.value === 0);

    const splitEnabled = computed( () => splitStore.isSplitEnabled(splitStore.currentSplit) && noOfSplit.value > 0);
    const splitDisabled = computed( () => splitStore.isSplitDisabled(splitStore.currentSplit) || noOfSplit.value === 0);

    function isSplitEnabled(split) {
      return splitStore.isSplitEnabled(split) && noOfSplit.value > 0;
    }

    function isSplitDisabled(split) {
      return splitStore.isSplitDisabled(split) || noOfSplit.value === 0
    }

    const activeTabIndex = ref(0);

    selectSplit(0);

    function numpadValue(defaultValue) {
      if( defaultValue === undefined ) {
        defaultValue = 1;
      }
      return props.numpadState.value > 0 ? props.numpadState.value : defaultValue;
    }

    function isAllSelected() {
      return props.numpadState.all;
    }

    function distributeAll() {
      splitStore.distributeAll();
    }

    function removeSplit(split) {
      if( split > 0 ) {
        splitStore.removeSplit(split);
        selectSplit(splitStore.currentSplit);
        activeTabIndex.value = splitStore.currentSplit - 1;
        ctx.emit('split:removed', split);
      }
    }

    function addProductToSplit(entry, split, noOfEntry) {
      if( noOfEntry === undefined ) {
        if( isAllSelected() ) {
          noOfEntry = entry.quantity;
        } else {
          noOfEntry = numpadValue();
        }
      }

      splitStore.addProductToSplit(entry, split, noOfEntry);

          // Scroll zu dem evtl. neu hinzugefügtem und evtl. nicht sichtbarem Element
      window.setTimeout(function() {
        const addedEntry = document.getElementById("process-entry-" + entry.id + "-" + split);
        addedEntry.scrollIntoView({behavior: "smooth", block: "nearest", inline:"nearest"})
      }, 200);

      ctx.emit('numpad:consumed');
      selectSplit(split);
    }

    function removeProductFromSplit(entry, split, noOfEntry) {
      if( split > 0 && entry.splittedEntries[split-1].quantity > 0) {
        if( noOfEntry === undefined ) {
          if( isAllSelected() ) {
            noOfEntry = entry.splittedEntries[split-1].quantity;
          } else {
            noOfEntry = numpadValue();
          }
        }
        splitStore.removeProductFromSplit(entry, split, noOfEntry);

        ctx.emit('numpad:consumed');

      }
      selectSplit(split);
    }

    function takeover(split) {
      if( split > 0 ) {
        splitStore.takeover(split);
        selectSplit(split);
      }
    }

    function resetSplit(split) {
      if( split > 0 ) {
        splitStore.resetSplit(split);
        selectSplit(split);
      }
    }

    function selectSplit(split) {
      splitStore.currentSplit = split;
      const splitInfo = splitStore.getCurrentSplitInfo();

      ctx.emit('split:selected', splitInfo);
    }

    function renderQuantity(value) {
      if( Number.isInteger(value) ) {
        return n(value, 'int')
      } else {
        return n(value, 'decimal')
      }
    }

    function tabChanged(event) {
      const newTabClicked = event.index >= splitStore.noOfSplit

      if( newTabClicked ) {
        ctx.emit("split:added");
      } else {
        selectSplit(event.index+1);
      }

    }

    function entryDetails(entry) {
      let details = [];
      if( entry.child_entries && entry.child_entries.length > 0 ) {
        details = entry.child_entries.map( c => {
          let detail = c.name;
          let childDetails = entryDetails(c);
          if( childDetails ) {
            detail += " (" + childDetails + ")";
          }
          return detail;
        })
      }

      return details.join(" / ");
    }

    function roundToTwo(num) {
      const m = Number((Math.abs(num) * 100).toPrecision(15));
      return Math.round(m) / 100 * Math.sign(num);
    }

    return {
      t,
      n,
      removeSplit,
      addProductToSplit,
      removeProductFromSplit,
      distributeAll,
      splitEntries,
      entriesForSplit,
      noOfSplit,
      selectSplit,
      takeover,
      resetSplit,
      renderQuantity,
      roundToTwo,
      tabChanged,
      entryDetails,
      currentSplit,
      splitStore,
      splitsActivated,
      splitsDeactivated,
      splitEnabled,
      splitDisabled,
      activeTabIndex,
      isSplitEnabled,
      isSplitDisabled
    };
  },
};
</script>
