<template>
  <div class="p-d-flex">
    <div class="flex-1">
      <mkeFormBuilderOneToManyPanel
          :modelValue="wysiwygDefinition"
          :elements="blockSchema"
          :add="addDialogDefinition"
          :edit="editDialogDefinition"
          @itemsUpdated="blocksEdited"
      />
    </div>
    <div class="p-d-flex p-flex-column p-ai-center flex-1">
      <InputText v-model.number="width" />
      <Slider v-model="width" :max="100" class="w-100"/>

      <div class="p-d-flex p-flex-column mx-print-preview flex-1">
        <div v-if="preview" v-safe-html="preview" style="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {inject, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";
import {usePrintLayoutStore} from "@/store/pinia/print_layout/PrintLayoutStore";


export default {
  name: "PrnFormBuilderPrintLayoutEditor",
  props: ["id", "data", "options", "store", "activeID", "level", "model", "field", "action"],
  emits: ["update:modelValue", "modelValueUpdate"],

  setup(props) {
    // const store = useStore()
    const panelVisible = ref(false);

    const modelData = inject("modelData");
    const setModelValue = inject('setModelValue');

    const editFieldsMapping = {
      "index": "index",
      "block_key": "block_key",
      "name": "name",
      "header": "header",
      "jinja_name": "jinja_name",
      "label": "label",
      "description": "description",
      "layout_type": "layout_type",
      "options": "options",
      "selected_options": "selected_options",
      "values": "values",
      "jinja_code": "jinja_code"
    }
    const addDialogDefinition = {
      "component": "PrnFormBuilderPrintLayoutBlockEditorDialog",
      "title": "message.masterdata.dialogs.add_layout_block",
      "mode": "add",
      "mapping" : editFieldsMapping
    }
    const editDialogDefinition = {
      "component": "PrnFormBuilderPrintLayoutBlockEditorDialog",
      "title": "message.masterdata.dialogs.edit_layout_block",
      "mode": "edit",
      "mapping" : editFieldsMapping
    }

    const printLayoutStore = usePrintLayoutStore();

    const {
      wysiwygDefinition,
        width
    } = storeToRefs(printLayoutStore);

    const { t } = useI18n({ useScope: 'global' })

    const blockSchema = [{
          "component": "mkeFormBuilderDisplayValue",
          "field": "label"
        }];

    const preview = ref("");

    const init = () => {
      console.log("LayoutEditor INIT")
      if( modelData?.value && props.field ) {
        printLayoutStore.init(modelData.value[props.field], modelData.value["content"])
        panelVisible.value = true;

        window.setTimeout(showPreview, 400);
      }
    }

    const blocksEdited = (data) => {
      wysiwygDefinition.value = data;
      showPreview();
    }

    const showPreview = () => {
      printLayoutStore.getPreview(wysiwygDefinition.value,
          (blocks, html, jinjaCode) => {
            setModelValue({field:"wysiwyg_definition", value: blocks})
            setModelValue({field:"content", value: jinjaCode})

            preview.value = html;
          },
          (error, html) => {
            preview.value = html;
          });
    }

    let widthTimeOutHandle = 0;

    const widthChanged = () => {
      if( widthTimeOutHandle > 0 ) {
        window.clearTimeout(widthTimeOutHandle);
      }
      widthTimeOutHandle = window.setTimeout(showPreview, 400)
    }

    watch(width, widthChanged );

    onMounted(() => {
      init();
    })

    return {t, panelVisible, wysiwygDefinition, blockSchema, addDialogDefinition, editDialogDefinition, blocksEdited, preview, width };
  }
}
</script>

<style scoped></style>
