<template>
  <Button class="p-button-transparent p-button-icon-only" type="" @click="toggleTheme" :class="{'p-d-none' : ignoreTheme}">
    <input
      @change="toggleTheme"
      id="checkbox"
      type="checkbox"
      class="switch-checkbox"
    />
    <label for="checkbox" class="switch-label">
      <span v-if="userTheme === 'dark-theme'">🌙</span>
      <span  v-if="userTheme === 'light-theme'">☀️</span>
      <div
        class="switch-toggle"
        :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"
      ></div>
    </label>
  </Button>
</template>

<script>
// import {useStore} from "vuex";
import store from "../../store";

export default {
  name: 'mkeSwitchThemeToggle',
    props: {
        ignoreTheme: Boolean
    },
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    let theme = this.ignoreTheme ? "dark-theme" : initUserTheme
    this.setTheme(theme);
  },

  data() {
    return {
      userTheme: "dark-theme",
    };
  },

  methods: {
    toggleTheme() {
      const activeTheme = this.getTheme();
      let theme = activeTheme === "light-theme" ? "dark-theme" : "light-theme"
      this.setTheme(theme);
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
      store.commit("general/setActiveTheme", theme)
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch-checkbox {
  display: none;
}
</style>
